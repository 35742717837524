import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import PageHelmet from "../PageHelmet";

import MarketplaceLink from "../MarketplaceLink";
import { cardsMarketplaces } from "../../utils/cardsMarketplaces";

import { proteinBarsProducts } from "./../ProteinBars/proteinBarsProducts.js";
import { proteinShakesProducts } from "./../ProteinShakes/proteinShakesProducts.js";
import { collagenProducts } from "./../Collagen/collagenProducts.js";
import { fatBurnersProducts } from "./../FatBurners/fatBurnersProducts.js";
import { detoxProducts } from "./../Detox/detoxProducts.js";
import { vitaminsProducts } from "./../Vitamins/vitaminsProducts.js";
import "./ProductDetail.css";
import arrowRight from "../../images/arrowRight.png";
import eyeBlack from "../../images/logoRight.png";

const productDatabases = {
  collagen: collagenProducts,
  "protein-bars": proteinBarsProducts,
  "protein-shakes": proteinShakesProducts,
  "fat-burners": fatBurnersProducts,
  detox: detoxProducts,
  vitamins: vitaminsProducts,
};

const ProductDetail = ({ headerHeight, handleGoBack }) => {
  const { productId, productKey } = useParams();
  const [product, setProduct] = useState(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [expandedSection, setExpandedSection] = useState(null);

  useEffect(() => {
    const products = productDatabases[productId];
    const selectedProduct = products ? products[productKey] : null;
    setProduct(selectedProduct);
  }, [productId, productKey]);

  useEffect(() => {
    if (product) {
      setCurrentImageIndex(0);
    }
  }, [product]); 

  const handlePrevImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? product.images.length - 1 : prevIndex - 1
    );
  };

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === product.images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handleIndicatorClick = (index) => {
    setCurrentImageIndex(index);
  };

  const toggleSection = (section) => {
    setExpandedSection((prevSection) =>
      prevSection === section ? null : section
    );
  };

  if (!product) {
    return <div>Товар не найден</div>;
  }

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "Product",
    name: product.name,
    description: product.description || `Откройте для себя ${product.name} со вкусом ${product.taste}.`,
    image: product.images.length > 0 ? product.images[0] : "",
    brand: {
      "@type": "Brand",
      name: "Japan Formula",
    },
    offers: {
      "@type": "Offer",
      url: `https://japanformula.com/products/${productId}/${productKey}`,
      priceCurrency: "RUB",
      // price: product.price ? product.price.toFixed(2) : "0.00",
      itemCondition: "https://schema.org/NewCondition",
      availability: product.inStock
        ? "https://schema.org/InStock"
        : "https://schema.org/OutOfStock",
    },
  };
  
  return (
    <section
      className="product-detail"
      style={{ paddingTop: `${headerHeight}px` }}
    >
      <PageHelmet
        title={product.name}
        description={product.description}
        image={product.image}
        canonicalUrl={`https://japanformula.com/products/${product.id}`}
        structuredData={structuredData}
        ogType="product"
      />
      <div className="product-detail__wrapper">
        <button
          className="article-products__button-main privacy-policy__button"
          type="button"
          onClick={handleGoBack}
        >
          назад в раздел
        </button>

        <div className="product-detail__block-text">
          <h1 className="product-detail__title">{product.title}</h1>
          <div className="product-detail__subtitle">{product.taste}</div>
        </div>

        <div className="product-detail__block-content">
          <div className="product-detail__img-wrapper">
            <div className="product-detail__img-container">
              <img
                className="main-reviews__arrow main-reviews__left-arrow hover"
                src={arrowRight}
                alt="значок стрелки налево"
                onClick={handlePrevImage}
              />
              <div className="product-detail__img-wrapper">
                <img
                  src={product.images[currentImageIndex]}
                  alt={product.title}
                  className="product-detail__img"
                />
              </div>
              <img
                className="main-reviews__arrow hover"
                src={arrowRight}
                alt="значок стрелки направо"
                onClick={handleNextImage}
              />
            </div>
            <div className="product-detail__indicators">
              {product.images.map((_, index) => (
                <span
                  key={index}
                  className={`indicator ${
                    currentImageIndex === index ? "active" : ""
                  }`}
                  onClick={() => handleIndicatorClick(index)}
                ></span>
              ))}
            </div>
          </div>

          <div className="product-detail__info">
            <div className="product-detail__section">
              <button
                className="product-detail__section-header"
                onClick={() => toggleSection("description")}
                title="Нажми, чтобы узнать подробнее"
              >
                <h3 className="product-detail__section-title">Описание</h3>
                <img src={eyeBlack} alt="eye icon" className="icon-right" />
              </button>
              {expandedSection === "description" && (
                <div className="product-detail__section-content">
                  {product.description}
                </div>
              )}
            </div>
            <div className="product-detail__section">
              <button
                className="product-detail__section-header"
                onClick={() => toggleSection("characteristics")}
                title="Нажми, чтобы узнать подробнее"
              >
                <h3 className="product-detail__section-title">
                  Характеристики
                </h3>
                <img src={eyeBlack} alt="eye icon" className="icon-right" />
              </button>
              {expandedSection === "characteristics" && (
                <div className="product-detail__section-content">
                  <div>
                    <h5 className="bold-text">Вкус:</h5> {product.taste}
                  </div>
                  <div>
                    <h5 className="bold-text">Форма выпуска:</h5>{" "}
                    {product.productType}
                  </div>
                  <div>
                    <h5 className="bold-text">Акцент на:</h5>{" "}
                    {product.textPurpose}
                  </div>
                  <div>
                    <h5 className="bold-text">Страна-изготовитель:</h5>{" "}
                    {product.country}
                  </div>
                  <div>
                    <h5 className="bold-text">Для кого:</h5> {product.gender}
                  </div>
                  <div>
                    <h5 className="bold-text">Срок годности в месяцах:</h5>
                    {product.expirationDate}
                  </div>
                  <div>
                    <h5 className="regular-text">Не является лекарством</h5>
                  </div>
                </div>
              )}
            </div>
            {productId === "protein-bars" ? (
              <div className="product-detail__section">
                <button
                  className="product-detail__section-header"
                  onClick={() => toggleSection("energyValue")}
                  title="Нажми, чтобы узнать подробнее"
                >
                  <h3 className="product-detail__section-title">
                    Пищевая ценность
                  </h3>
                  <img src={eyeBlack} alt="eye icon" className="icon-right" />
                </button>
                {expandedSection === "energyValue" && (
                  <div className="product-detail__section-content">
                    <div>
                      <h5 className="bold-text">
                        Энергетическая ценность на 100 г:{" "}
                      </h5>
                      {product.energyValue} ккал
                    </div>
                    <div>
                      <h5 className="bold-text">Белок: грамм в 1 порции: </h5>
                      {product.nutritionalValueProteins}
                    </div>
                    <div>
                      <h5 className="bold-text">Жиры: грамм в 1 порции: </h5>
                      {product.nutritionalValueFats}
                    </div>
                    <div>
                      <h5 className="bold-text">
                        Углеводы: грамм в 1 порции:{" "}
                      </h5>{" "}
                      {product.nutritionalValueCarbohydrates}
                    </div>
                    <br />
                    <div>
                      <h5 className="bold-text">Особенности: </h5>
                      {product.text}
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className="product-detail__section">
                <button
                  className="product-detail__section-header"
                  onClick={() => toggleSection("contentMethod")}
                  title="Нажми, чтобы узнать подробнее"
                >
                  <h3 className="product-detail__section-title">Применение</h3>
                  <img src={eyeBlack} alt="eye icon" className="icon-right" />
                </button>
                {expandedSection === "contentMethod" && (
                  <div className="product-detail__section-content">
                    <div>
                      <h5 className="bold-text">Способ применения: </h5>
                      {product.contentMethod}
                    </div>
                    <div>
                      <h5 className="bold-text">{product.water}</h5>
                    </div>

                    <div>
                      <h5 className="bold-text">Особенности: {product.text}</h5>
                    </div>
                  </div>
                )}
              </div>
            )}
            <div className="product-detail__section">
              <button
                className="product-detail__section-header"
                onClick={() => toggleSection("composition")}
                title="Нажми, чтобы узнать подробнее"
              >
                <h3 className="product-detail__section-title">Состав</h3>
                <img src={eyeBlack} alt="eye icon" className="icon-right" />
              </button>
              {expandedSection === "composition" && (
                <div className="product-detail__section-content">
                  <div>{product.composition}</div>
                </div>
              )}
            </div>
            <div className="product-detail__section">
              <button
                className="product-detail__section-header"
                onClick={() => toggleSection("contraindications")}
                title="Нажми, чтобы узнать подробнее"
              >
                <h3 className="product-detail__section-title">
                  Противопоказания
                </h3>
                <img src={eyeBlack} alt="eye icon" className="icon-right" />
              </button>
              {expandedSection === "contraindications" && (
                <div className="product-detail__section-content">
                  <div>{product.contraindications}</div>
                </div>
              )}
            </div>
            <div className="product-detail__section">
              <button
                className="product-detail__section-header"
                onClick={() => toggleSection("price")}
                title="Нажми, чтобы узнать подробнее"
              >
                <h3 className="product-detail__section-title">Цена</h3>
                <img src={eyeBlack} alt="eye icon" className="icon-right" />
              </button>
              {expandedSection === "price" && (
                <div className="product-detail__section-content">
                  <div>
                    <h5 className="bold-text">
                      Рекомендованная розничная цена:{" "}
                    </h5>
                    {product.price} руб.
                  </div>
                  <br />
                  <div>
                    <h5 className="bold-text">
                      Мы всегда стремимся предложить нашим клиентам
                      высококачественные продукты по справедливой цене. Однако,
                      для вашего удобства и возможной экономии, рекомендуем
                      также ознакомиться с ценами на наши продукты на различных
                      онлайн-маркетплейсах.
                    </h5>
                  </div>
                  <br />
                  <div>
                    <p className="bold-text">
                      Почему стоит проверить цены на маркетплейсах:
                    </p>
                    <br />

                    <p>Акции и скидки:</p>
                    <p>
                      Онлайн-магазины и маркетплейсы часто проводят акции и
                      предлагают скидки, которые могут сделать покупку более
                      выгодной.
                    </p>
                    <br />

                    <p>Специальные предложения:</p>
                    <p>
                      На маркетплейсах можно найти эксклюзивные предложения,
                      которых нет на нашем сайте.
                    </p>
                    <p>
                      Проверяйте цены на наших продуктах на популярных
                      маркетплейсах, чтобы найти наиболее выгодные предложения и
                      сэкономить на покупках.
                    </p>
                    <div className="product-detail__marketplace-list">
          {cardsMarketplaces.map((marketplace) => (
            <MarketplaceLink key={marketplace.name} href={marketplace.url}>
              <img
                className="product-detail__marketplace-item"
                src={marketplace.img}
                alt={marketplace.name}
                title={marketplace.name}
              />
            </MarketplaceLink>
          ))}
        </div>
                  </div>
                </div>
              )}
            </div>
            <div>
              <p className="medium-text">
                Информация о технических характеристиках, комплектации поставки,
                внешнем виде и цвете товара носит справочный характер и
                основывается на последних доступных к моменту публикации
                сведениях.
              </p>
              <p className="medium-text">
                Описание препаратов и их свойств не заменяет обращения к врачу.
                Имеются противопоказания, перед применением проконсультируйтесь
                со специалистом!
              </p>
              <p className="medium-text">
                Вес продукта может незначительно отличаться.
              </p>
            </div>
          </div>
        </div>
        <button
          className="article-products__button-main privacy-policy__button"
          type="button"
          onClick={handleGoBack}
        >
          назад в раздел
        </button>
      </div>
    </section>
  );
};

export default ProductDetail;
