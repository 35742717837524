import React from "react";

import PageHelmet from "../PageHelmet";

import "./About.css";
import logo from "../../images/logo.png";

import AboutUs from "../AboutUs/AboutUs";
import ChooseUs from "../ChooseUs/ChooseUs";
import Values from "../Values/Values";
import SocialMedia from "../SocialMedia/SocialMedia";
import Cooperation from "../Cooperation/Cooperation";

const About = ({
  headerHeight,
  handleGoMain,
  handleGoStore,
  handleGoGift,
  handleTelegramClickSupport,
  handleTelegramClickJF,
  handleVkClick,
}) => {
  return (
    <nav className="about" id="about">
      <PageHelmet
        title="Ценности компании 'Japan Formula'"
        description="Продукция для поддержания активного и здорового образа жизни, от спортивного питания до витаминов и БАДов."
        keywords="БАДы, витамины, спортивное питание"
        image={logo}
        canonicalUrl="https://japanformula.com/about/"
      />
      <AboutUs
        headerHeight={headerHeight}
        handleGoMain={handleGoMain}
        handleGoStore={handleGoStore}
        handleGoGift={handleGoGift}
      />
      <ChooseUs />
      <Values handleGoMain={handleGoMain} />
      <SocialMedia
        handleTelegramClickJF={handleTelegramClickJF}
        handleVkClick={handleVkClick}
      />
      <Cooperation handleTelegramClickSupport={handleTelegramClickSupport}/>
    </nav>
  );
};

export default About;
