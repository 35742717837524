// PageHelmet.jsx
import React from "react";
import { Helmet } from "react-helmet-async";
import BaseHelmet from "./BaseHelmet";

const PageHelmet = ({
  title,
  description,
  keywords,
  image,
  canonicalUrl,
  structuredData,
  ogType = "website",
}) => (
  <>
    <BaseHelmet />
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <link rel="canonical" href={canonicalUrl} />
      
      {/* Open Graph */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      <meta property="og:url" content={canonicalUrl} />
      <meta property="og:type" content={ogType} />

      {/* Twitter Card */}
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
      <meta name="twitter:card" content="summary_large_image" />

      {/* JSON-LD (структурированные данные) */}
      {structuredData && (
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      )}
    </Helmet>
  </>
);

export default PageHelmet;
