import React from "react";

import PageHelmet from "../PageHelmet";

import "./Main.css";
import logo from "../../images/logo.png";

import MainTitle from "../MainTitle/MainTitle";
import MainSlides from "../MainSlides/MainSlides";
import MainTaste from "../MainTaste/MainTaste";
import MainReviews from "../MainReviews/MainReviews";
import SocialMedia from "../SocialMedia/SocialMedia";

const Main = ({
  headerHeight,
  openComponent,
  handleGoGift,
  handleTelegramClickJF,
  handleVkClick,
}) => {
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    name: "Japan Formula - Натуральные добавки для здоровья",
    description:
      "Натуральные добавки Japan Formula для красоты и здоровья. Создано в Японии, доступно в России.",
    url: "https://japanformula.com/",
    image: "https://japanformula.com/main-image.webp",
    publisher: {
      "@type": "Organization",
      name: "Japan Formula",
      logo: {
        "@type": "ImageObject",
        url: "https://japanformula.com/logo.png",
      },
    },
  };

  return (
    <nav className="main" id="main">
      <PageHelmet
        title="Japan Formula - Натуральные добавки для здоровья"
        description="Натуральные добавки Japan Formula для красоты и здоровья. Создано в Японии, доступно в России."
        keywords="Japan Formula, японские добавки, здоровье, витамины, натуральные продукты"
        image="https://japanformula.com/main-image.webp"
        canonicalUrl="https://japanformula.com/"
        structuredData={structuredData}
      />
      <MainTitle headerHeight={headerHeight} handleGoGift={handleGoGift} />
      <MainSlides openComponent={openComponent} />
      <MainTaste />
      <SocialMedia
        handleTelegramClickJF={handleTelegramClickJF}
        handleVkClick={handleVkClick}
      />
      <MainReviews />
    </nav>
  );
};

export default Main;
