import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { proteinBarsProducts } from "./../ProteinBars/proteinBarsProducts.js";
import { proteinShakesProducts } from "./../ProteinShakes/proteinShakesProducts.js";
import { collagenProducts } from "./../Collagen/collagenProducts.js";
import { fatBurnersProducts } from "./../FatBurners/fatBurnersProducts.js";
import { detoxProducts } from "./../Detox/detoxProducts.js";
import { vitaminsProducts } from "./../Vitamins/vitaminsProducts.js";
import { cardsData } from "../../utils/cardsData.js";
import "./ProductsByPurpose.css";
import products from "../../images/imgPurpose.png";

import PageHelmet from "../PageHelmet";

const productDatabases = {
  collagen: collagenProducts,
  "protein-bars": proteinBarsProducts,
  "protein-shakes": proteinShakesProducts,
  "fat-burners": fatBurnersProducts,
  detox: detoxProducts,
  vitamins: vitaminsProducts,
};

const ProductsByPurpose = ({ headerHeight, handleGoBack, handleGoStore }) => {
  const { purpose } = useParams(); // получаем значение purpose из URL
  const navigate = useNavigate();

  // Находим объект в cardsData по английскому purpose
  const card = cardsData.find(
    (card) => card.purpose.toLowerCase() === purpose.toLowerCase()
  );

  // Если карта найдена, получаем goal (русское значение), если нет, устанавливаем как undefined или задаем дефолтное значение
  const goal = card ? card.goal : "Цель не найдена";

  const filteredProducts = Object.values(productDatabases)
    .flatMap((db) => Object.values(db))
    .filter(
      (product) =>
        product.purposes &&
        product.purposes.some((p) => p.toLowerCase() === goal.toLowerCase()) // используем goal для фильтрации продуктов
    );

  const handleProductClick = (productId, productKey) => {
    navigate(`/products/${productId}/${productKey}`);
  };

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "CollectionPage",
    name: `Продукты для ${goal}`,
    description: `Подборка продуктов Japan Formula, которые помогут вам достичь цели: ${goal}.`,
    hasPart: filteredProducts.map((product) => {
      const productId = Object.keys(productDatabases).find((key) =>
        Object.values(productDatabases[key]).includes(product)
      );
      const productKey = Object.keys(productDatabases[productId]).find(
        (key) => productDatabases[productId][key] === product
      );
  
      return {
        "@type": "Product",
        name: product.name,
        image: product.img,
        url: `https://japanformula.com/products/${productId}/${productKey}`,
      };
    }),
  };
  

  return (
    <div
      className="products-by-purpose"
      style={{ paddingTop: `${headerHeight}px` }}
    >
      <PageHelmet
        title={`Продукты для ${purpose} - Japan Formula`}
        description={`Выберите продукты Japan Formula, которые помогут вам ${purpose}.`}
        canonicalUrl={`https://japanformula.com/products-by-purpose/${purpose}`}
        structuredData={structuredData}
        ogType="website"
      />
      <div className="products-by-purpose__wrapper">
        <div className="products__image-container">
          <img className="products__img" src={products} alt="Плакат фирмы" />
        </div>
        <div className="about-us__button-block">
          <button
            className="about-us__button-main privacy-policy__button"
            type="button"
            onClick={handleGoBack}
          >
            НАЗАД
          </button>
          <button
            className="about-us__button-main privacy-policy__button"
            type="button"
            onClick={handleGoStore}
          >
            Где купить
          </button>
        </div>

        <h1 className="products-by-purpose__title">
          Ваш путь к цели <span className="red-text">{goal}</span> начинается
          здесь
        </h1>
        <div className="products-by-purpose__block-container">
          <ul className="products-by-purpose__list">
            {filteredProducts.length > 0 ? (
              filteredProducts.map((product, index) => {
                const productId = Object.keys(productDatabases).find((key) =>
                  Object.values(productDatabases[key]).includes(product)
                );
                const productKey = Object.keys(
                  productDatabases[productId]
                ).find((key) => productDatabases[productId][key] === product);

                return (
                  <li
                    key={index}
                    className="products-by-purpose__item"
                    onClick={() => handleProductClick(productId, productKey)}
                  >
                    <div className="products-by-purpose__content">
                      <img
                        src={product.img}
                        alt={product.title}
                        className="products-by-purpose__image"
                      />
                      <h2 className="products-by-purpose__product-title">
                        {product.title}
                      </h2>
                    </div>
                  </li>
                );
              })
            ) : (
              <p className="products-by-purpose__title-non">
                Нет продуктов, соответствующих выбранной цели.
              </p>
            )}
          </ul>
        </div>
        <button
          className="products-by-purpose__button-main privacy-policy__button"
          type="button"
          onClick={handleGoBack}
        >
          НАЗАД
        </button>
      </div>
    </div>
  );
};

export default ProductsByPurpose;
