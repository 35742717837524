import CPBMix11 from "./imagesProteinBars/CPB- Mix11.webp";
import CPBMix12 from "./imagesProteinBars/CPB- Mix12.webp";
import CPBMix13 from "./imagesProteinBars/CPB- Mix13.webp";
import CPBMix14 from "./imagesProteinBars/CPB- Mix14.webp";
import CPBSK11 from "./imagesProteinBars/CPB- SK11.webp";
import CPBSK12 from "./imagesProteinBars/CPB- SK12.webp";
import CPBSK13 from "./imagesProteinBars/CPB- SK13.webp";
import CPBSK14 from "./imagesProteinBars/CPB- SK14.webp";
import CPBM11 from "./imagesProteinBars/CPB-M11.webp";
import CPBM12 from "./imagesProteinBars/CPB-M12.webp";
import CPBM13 from "./imagesProteinBars/CPB-M13.webp";
import CPBM14 from "./imagesProteinBars/CPB-M14.webp";
import CPBC11 from "./imagesProteinBars/CPB-C11.webp";
import CPBC12 from "./imagesProteinBars/CPB-C12.webp";
import CPBC13 from "./imagesProteinBars/CPB-C13.webp";
import CPBC14 from "./imagesProteinBars/CPB-C14.webp";
import CPBCh11 from "./imagesProteinBars/CPB-Ch11.webp";
import CPBCh12 from "./imagesProteinBars/CPB-Ch12.webp";
import CPBCh13 from "./imagesProteinBars/CPB-Ch13.webp";
import CPBCh14 from "./imagesProteinBars/CPB-Ch14.webp";
import PBMix11 from "./imagesProteinBars/PB- Mix11.webp";
import PBMix12 from "./imagesProteinBars/PB- Mix12.webp";
import PBMix13 from "./imagesProteinBars/PB- Mix13.webp";
import PBMix14 from "./imagesProteinBars/PB- Mix14.webp";
import PBSK11 from "./imagesProteinBars/PB- SK11.webp";
import PBSK12 from "./imagesProteinBars/PB- SK12.webp";
import PBSK13 from "./imagesProteinBars/PB- SK13.webp";
import PBSK14 from "./imagesProteinBars/PB- SK14.webp";
import PBM11 from "./imagesProteinBars/PB- M11.webp";
import PBM12 from "./imagesProteinBars/PB- M12.webp";
import PBM13 from "./imagesProteinBars/PB- M13.webp";
import PBM14 from "./imagesProteinBars/PB- M14.webp";
import PBRR11 from "./imagesProteinBars/PB- RR11.webp";
import PBRR12 from "./imagesProteinBars/PB- RR12.webp";
import PBRR13 from "./imagesProteinBars/PB- RR13.webp";
import PBRR14 from "./imagesProteinBars/PB- RR14.webp";
import PBCh11 from "./imagesProteinBars/PB- Ch11.webp";
import PBCh12 from "./imagesProteinBars/PB- Ch12.webp";
import PBCh13 from "./imagesProteinBars/PB- Ch13.webp";
import PBCh14 from "./imagesProteinBars/PB- Ch14.webp";

import nuts01 from "./imagesProteinBars/nuts01.webp";
import mix11 from "./imagesProteinBars/mix11.webp";
import mix12 from "./imagesProteinBars/mix12.webp";
import mix13 from "./imagesProteinBars/mix13.webp";
import mix14 from "./imagesProteinBars/mix14.webp";
import pistachio11 from "./imagesProteinBars/pistachio11.webp";
import pistachio12 from "./imagesProteinBars/pistachio12.webp";
import pistachio13 from "./imagesProteinBars/pistachio13.webp";
import nuts11 from "./imagesProteinBars/nuts11.webp";
import nuts12 from "./imagesProteinBars/nuts12.webp";
import nuts13 from "./imagesProteinBars/nuts13.webp";
import caramel11 from "./imagesProteinBars/caramel11.webp";
import caramel12 from "./imagesProteinBars/caramel12.webp";
import caramel13 from "./imagesProteinBars/caramel13.webp";
import berries11 from "./imagesProteinBars/berries11.webp";
import berries12 from "./imagesProteinBars/berries12.webp";
import berries13 from "./imagesProteinBars/berries13.webp";

import newCrml11 from "./imagesProteinBars/Crml11.webp";
import newCrml12 from "./imagesProteinBars/Crml12.webp";
import newCrml13 from "./imagesProteinBars/Crml13.webp";
import newCrml14 from "./imagesProteinBars/Crml14.webp";

import newLL11 from "./imagesProteinBars/LL11.webp";
import newLL12 from "./imagesProteinBars/LL12.webp";
import newLL13 from "./imagesProteinBars/LL13.webp";
import newLL14 from "./imagesProteinBars/LL14.webp";

import newWB11 from "./imagesProteinBars/WB11.webp";
import newWB12 from "./imagesProteinBars/WB12.webp";
import newWB13 from "./imagesProteinBars/WB13.webp";
import newWB14 from "./imagesProteinBars/WB14.webp";

import newNut11 from "./imagesProteinBars/Nut11.webp";
import newNut12 from "./imagesProteinBars/Nut12.webp";
import newNut13 from "./imagesProteinBars/Nut13.webp";
import newNut14 from "./imagesProteinBars/Nut14.webp";

import newMango11 from "./imagesProteinBars/newM11.webp";
import newMango12 from "./imagesProteinBars/newM12.webp";
import newMango13 from "./imagesProteinBars/newM13.webp";
import newMango14 from "./imagesProteinBars/newM14.webp";

import raspberryMuffin11 from "./imagesProteinBars/RM11.webp";
import raspberryMuffin12 from "./imagesProteinBars/RM12.webp";
import raspberryMuffin13 from "./imagesProteinBars/RM13.webp";
import raspberryMuffin14 from "./imagesProteinBars/RM14.webp";

import chocolateCake11 from "./imagesProteinBars/CC11.webp";
import chocolateCake12 from "./imagesProteinBars/CC12.webp";
import chocolateCake13 from "./imagesProteinBars/CC13.webp";
import chocolateCake14 from "./imagesProteinBars/CC14.webp";

import strawberriesCream11 from "./imagesProteinBars/SC11.webp";
import strawberriesCream12 from "./imagesProteinBars/SC12.webp";
import strawberriesCream13 from "./imagesProteinBars/SC13.webp";
import strawberriesCream14 from "./imagesProteinBars/SC14.webp";

export const proteinBarsProducts = {
    'protein-bars-without-sugar-salted-caramel': {
        title: 'Протеиновые батончики без сахара Соленая Карамель, с экстрактами гуараны и семян годжи',
        productType: 'Энергетический батончик',
        description: (
            <>
                <p>Спортивный протеиновый батончик без сахара Соленая Карамель, с экстрактами гуараны и семян годжи от Japan Formula вкусное и полезное дополнение к вашему рациону!  </p>
                <br />
                <p>В составе этой вкусняшки идеальное соотношение КБЖУ, низкое количество углеводов и высокое содержание белка за счет наличия протеина.  В коробке 16 штук, вес 1го батончика 40грамм. Допускается небольшое отклонение+-5грамма.</p>
            </>
        ),
        price: '700 ',
        tasteEng: 'caramel; peanuts',
        taste: 'карамель; арахис',
        energyValue: '93',
        nutritionalValueProteins: '13.2',
        nutritionalValueFats: '3.2',
        nutritionalValueCarbohydrates: '45',
        textPurpose: 'Восстановление, Выносливость, Красота и здоровье, Набор мышечной массы, Повышение работоспособности, Похудение/Сушка, Профилактика, Сила',
        purposes: ['здоровье кожи, волос и ногтей', 'энергия', 'восстановление', 'красота и здоровье'],
        composition: 'Изолят соевого белка, сироп олигофруктозы, глазурь кондитерская (Подсластитель мальтит, заменитель масла какао нетемперируемый лауринового типа, какао-порошок, эмульгатор лецитин, ароматизатор), арахис жареный, молочная сыворотка, сорбитол, кокосовое масло, вода, изолят сывороточного белка, глицерин, лимонная кислота, натуральные ароматизаторы, лецитин, сорбат калия, стевия, аскорбиновая кислота, экстракт ягод годжи, экстракт гуараны.',
        text: 'без глютена, без сахара, без ГМО',
        country: 'Россия',
        expirationDate: '24',
        storageConditions: 'В защищенном от солнца помещении, В прохладном темном месте, В сухом прохладном месте',
        gender: 'Женский, Мужской',
        images: [newCrml11, newCrml12, newCrml13, newCrml14],
        img: newCrml11,
        contraindications: <>
            <p>1. Индивидуальная непереносимость ингредиентов : В состав могут входить компоненты, такие как лактоза, глютен и орехи, которые могут вызвать непереносимость.</p>
            <p>2. Пациентам с хроническими заболеваниями : Людям с хроническими заболеваниями сердца, почек или печени следует проконсультироваться с врачом перед употреблением.</p>
            <p>3. Беременные и кормящие женщины : Употребление продукта должно быть согласовано с лечащим врачом.</p>
            <p>4. Маленькие дети : Продукт не предназначен для детей младше 3 лет.</p>
        </>
    },
    'protein-bars-without-sugar-wild-berries': {
        title: 'Протеиновые батончики без сахара Лесные Ягоды, с экстрактами гуараны и семян годжи',
        productType: 'Диетический батончик',
        description: (
            <>
                <p>Спортивный протеиновый батончик без сахара Лесные Ягоды, с экстрактами гуараны и семян годжи от Japan Formula это диетическое низкокалорийное питание без глютена для тех, кто занимается спортом и следит за своим весом.В составе батончика только натуральные ингредиенты, без сахара, без глютена, пальмового масла и ГМО </p>
                <br />
                <p>Батончик не занимает много места и вы можете взять его с собой куда угодно, так у вас всегда будет при себе полезный, вкусный и низкокалорийный перекус.</p>
            </>
        ),
        price: '800 ',
        tasteEng: 'Wild berries',
        taste: 'Лесные ягоды',
        energyValue: '93',
        nutritionalValueProteins: '13.2',
        nutritionalValueFats: '3.2',
        nutritionalValueCarbohydrates: '45',
        textPurpose: 'Выносливость, Повышение работоспособности, Красота и здоровье, Набор мышечной массы, Похудение/Сушка, Профилактика, Сила',
        purposes: ['здоровье кожи, волос и ногтей', 'энергия', 'восстановление', 'красота и здоровье'],
        composition: 'Изолят соевого белка, сироп олигофруктозы, глазурь кондитерская (Подсластитель мальтит, заменитель масла какао нетемперируемый лауринового типа, какао-порошок, эмульгатор лецитин, ароматизатор), арахис жареный, молочная сыворотка, сорбитол, кокосовое масло, вода, изолят сывороточного белка, глицерин, лимонная кислота, натуральные ароматизаторы, лецитин, сорбат калия, стевия, аскорбиновая кислота, экстракт ягод годжи, экстракт гуараны.',
        text: 'без глютена, без сахара, без ГМО, без пальмового масла',
        country: 'Россия',
        expirationDate: '12',
        storageConditions: 'В защищенном от солнца помещении, В прохладном темном месте, В сухом прохладном месте',
        gender: 'Женский, Мужской',
        images: [newWB11, newWB12, newWB13, newWB14,],
        img: newWB11,
        contraindications: <>
            <p>1. Индивидуальная непереносимость ингредиентов : В состав могут входить компоненты, такие как лактоза, глютен и орехи, которые могут вызвать непереносимость.</p>
            <p>2. Пациентам с хроническими заболеваниями : Людям с хроническими заболеваниями сердца, почек или печени следует проконсультироваться с врачом перед употреблением.</p>
            <p>3. Беременные и кормящие женщины : Употребление продукта должно быть согласовано с лечащим врачом.</p>
            <p>4. Маленькие дети : Продукт не предназначен для детей младше 3 лет.</p>
        </>
    },
    'protein-bars-without-sugar-lemon-lime': {
        title: 'Протеиновые батончики без сахара Лимон - Лайм, с экстрактами гуараны и семян годжи',
        productType: 'Диетический батончик',
        description: (
            <>
                <p>Протеиновые батончики без сахара Лимон - Лайм, с экстрактами гуараны и семян годжи от Japan Formula это диетическое низкокалорийное питание без глютена для тех, кто занимается спортом и следит за своим весом. </p>
                <br />
                <p>Протеиновый батончик - это не только продукт правильного и здорового питания, но еще и отличный десерт, который заменит вредные сладости и не даст сорваться с диеты.</p>
            </>
        ),
        price: '800',
        tasteEng: 'Lemon  Lime',
        taste: 'Лимон - Лайм',
        energyValue: '140',
        nutritionalValueProteins: '13.2',
        nutritionalValueFats: '3.3',
        nutritionalValueCarbohydrates: '15',
        textPurpose: 'Восстановление, Выносливость, Красота и здоровье, Набор мышечной массы, Похудение/Сушка, Профилактика, Сила',
        purposes: ['здоровье кожи, волос и ногтей', 'энергия', 'восстановление', 'красота и здоровье'],
        composition: 'Изолят соевого белка, сироп олигофруктозы, глазурь кондитерская (Подсластитель мальтит, заменитель масла какао нетемперируемый лауринового типа, какао-порошок, эмульгатор лецитин, ароматизатор), арахис жареный, молочная сыворотка, сорбитол, кокосовое масло, вода, изолят сывороточного белка, глицерин, лимонная кислота, натуральные ароматизаторы, лецитин, сорбат калия, стевия, аскорбиновая кислота, экстракт ягод годжи, экстракт гуараны.',
        text: 'без сахара, без консервантов, без ГМО, без глютена',
        country: 'Россия',
        expirationDate: '12',
        storageConditions: 'В оригинальной упаковке, В сухом прохладном месте',
        gender: 'Женский, Мужской',
        images: [newLL11, newLL12, newLL13, newLL14,],
        img: newLL11,
        contraindications: <>
            <p>1. Индивидуальная непереносимость ингредиентов : В состав могут входить компоненты, такие как лактоза, глютен и орехи, которые могут вызвать непереносимость.</p>
            <p>2. Пациентам с хроническими заболеваниями : Людям с хроническими заболеваниями сердца, почек или печени следует проконсультироваться с врачом перед употреблением.</p>
            <p>3. Беременные и кормящие женщины : Употребление продукта должно быть согласовано с лечащим врачом.</p>
            <p>4. Маленькие дети : Продукт не предназначен для детей младше 3 лет.</p>
        </>
    },
    'protein-bars-without-sugar-nut-mix': {
        title: 'Протеиновые батончики без сахара Ореховый микс, с экстрактами гуараны и семян годжи',
        productType: 'Энергетический батончик',
        description: (
            <>
                <p>Протеиновые спортивные батончики без сахара Ореховый микс, с экстрактами гуараны и семян годжи от Japan Formula это Вкусный и полезный перекус без сахара! С его помощью вы без проблем восполните дневную норму белка, а также сократите количество приемов пищи и эффективно сбросите лишний вес.</p>
                <br />
                <p> Батончик идеален и для спортсменов, так как он помогает в наборе мышечной массы и дает большой заряд бодрости и энергии. Батончик не занимает много места и вы можете взять его с собой куда угодно, так у вас всегда будет при себе полезный, вкусный и низкокалорийный перекус.</p>
            </>
        ),
        price: '1100 ',
        tasteEng: 'nut',
        taste: 'орех',
        energyValue: '140',
        nutritionalValueProteins: '13.2',
        nutritionalValueFats: '3.3',
        nutritionalValueCarbohydrates: '15',
        textPurpose: 'Восстановление, Выносливость, Красота и здоровье, Набор мышечной массы, Повышение работоспособности, Профилактика, Похудение/Сушка, Сила',
        purposes: ['здоровье кожи, волос и ногтей', 'энергия', 'восстановление', 'красота и здоровье'],
        composition: 'Изолят соевого белка, сироп олигофруктозы, глазурь кондитерская (Подсластитель мальтит, заменитель масла какао нетемперируемый лауринового типа, какао-порошок, эмульгатор лецитин, ароматизатор), арахис жареный, молочная сыворотка, сорбитол, кокосовое масло, вода, изолят сывороточного белка, глицерин, лимонная кислота, натуральные ароматизаторы, лецитин, сорбат калия, стевия, аскорбиновая кислота, экстракт ягод годжи, экстракт гуараны.',
        text: 'без ГМО, без глютена, без сахара',
        country: 'Россия',
        expirationDate: '12',
        storageConditions: 'В защищенном от солнца помещении, В прохладном темном месте, В сухом прохладном месте',
        gender: 'Женский, Мужской',
        images: [newNut11, newNut12, newNut13, newNut14,],
        img: newNut11,
        contraindications: <>
            <p>1. Индивидуальная непереносимость ингредиентов : В состав могут входить компоненты, такие как лактоза, глютен и орехи, которые могут вызвать непереносимость.</p>
            <p>2. Пациентам с хроническими заболеваниями : Людям с хроническими заболеваниями сердца, почек или печени следует проконсультироваться с врачом перед употреблением.</p>
            <p>3. Беременные и кормящие женщины : Употребление продукта должно быть согласовано с лечащим врачом.</p>
            <p>4. Маленькие дети : Продукт не предназначен для детей младше 3 лет.</p>
        </>
    },
    'protein-bars-without-glaze-strawberries-with-cream': {
        title: 'Протеиновые батончики без глазури Клубника со сливками',
        productType: 'Протеиновый батончик',
        description: (
            <>
                <p>Спортивный батончик без глазури от Japan Formula это диетическое питание без глютена для тех, кто занимается спортом и следит за своей фигурой. В составе идеальное соотношение КБЖУ, низкое количество углеводов и высокое содержание белка за счет наличия протеина. </p>
                <br />
                <p>Protein бар это нет только полезный низкокалорийный пп десерт, но и прекрасный белковый перекус в зале и фитнесе. Побалуйте себя шоколадными сладостями без вреда для здоровья. В коробке 16 штук, вес 1го батончика 40грамм. Допускается небольшое отклонение+-5грамма.</p>
            </>
        ),
        price: '900 ',
        tasteEng: 'Strawberries with cream ',
        taste: 'Клубника со сливками',
        energyValue: '144',
        nutritionalValueProteins: '13.2',
        nutritionalValueFats: '3.3',
        nutritionalValueCarbohydrates: '16',
        textPurpose: 'Восстановление, Выносливость, Красота и здоровье, Набор мышечной массы, Повышение работоспособности, Похудение/Сушка, Профилактика, Сила',
        purposes: ['здоровье кожи, волос и ногтей', 'энергия', 'восстановление', 'красота и здоровье'],
        composition: 'Мальтодекстрин, изолят соевого белка, вода, патока, сорбитол, кокосовое масло,; водорастворимое волокно кукурузы, глицерин, пищевые ароматизаторы, лецитин, клубника сушеная,; пищевой краситель, стевиозид, регулятор кислотности лимонная кислота,; сорбат калия, аскорбиновая кислота',
        text: 'без глютена, без транс жиров, без ГМО',
        country: 'Россия',
        expirationDate: '12',
        storageConditions: 'В защищенном от солнца помещении, В прохладном темном месте, В сухом прохладном месте',
        gender: 'Женский, Мужской',
        images: [strawberriesCream11, strawberriesCream12, strawberriesCream13, strawberriesCream14],
        img: strawberriesCream11,
        contraindications: <>
            <p>1. Индивидуальная непереносимость ингредиентов : В состав могут входить компоненты, такие как лактоза, глютен и орехи, которые могут вызвать непереносимость.</p>
            <p>2. Пациентам с хроническими заболеваниями : Людям с хроническими заболеваниями сердца, почек или печени следует проконсультироваться с врачом перед употреблением.</p>
            <p>3. Беременные и кормящие женщины : Употребление продукта должно быть согласовано с лечащим врачом.</p>
            <p>4. Маленькие дети : Продукт не предназначен для детей младше 3 лет.</p>
        </>
    },
    'protein-bars-without-glaze-mango': {
        title: 'Протеиновые батончики без глазури Манго',
        productType: 'Протеиновый батончик',
        description: (
            <>
                <p>Спортивный батончик без глазури от Japan Formula это диетическое питание без глютена для тех, кто занимается спортом и следит за своей фигурой. В составе идеальное соотношение КБЖУ, низкое количество углеводов и высокое содержание белка за счет наличия протеина. </p>
                <br />
                <p>Protein бар это нет только полезный низкокалорийный пп десерт, но и прекрасный белковый перекус в зале и фитнесе. Побалуйте себя шоколадными сладостями без вреда для здоровья. В коробке 16 штук, вес 1го батончика 40грамм. Допускается небольшое отклонение+-5грамма.</p>
            </>
        ),
        price: '800 ',
        tasteEng: 'Mango',
        taste: 'Манго',
        energyValue: '144',
        nutritionalValueProteins: '13.2',
        nutritionalValueFats: '3.3',
        nutritionalValueCarbohydrates: '16',
        textPurpose: 'Восстановление, Выносливость, Красота и здоровье, Набор мышечной массы, Повышение работоспособности, Похудение/Сушка, Профилактика, Сила',
        purposes: ['здоровье кожи, волос и ногтей', 'энергия', 'восстановление', 'красота и здоровье'],
        composition: 'Мальтодекстрин , изолят соевого белка, глазурь кондитерская (мальтит,; заменитель какао масла лауринового типа, сухая молочная сыворотка,; Сухое обезжиренное молоко ,какао порошок, эмульгатор е322, ароматизатор ванилин, стевеозид),; Декстрин, вода , карамельная патока , Сорбитол, кокосовое масло,; Глицерин , ароматизатор, Манго сушеное, лецитин, краситель,Стевиозид,; лимонная кислота, сорбат кальция, аскорбиновая кислота',
        text: 'без глютена, без транс жиров, без ГМО',
        country: 'Россия',
        expirationDate: '12',
        storageConditions: 'В защищенном от солнца помещении, В прохладном темном месте, В сухом прохладном месте',
        gender: 'Женский, Мужской',
        images: [newMango11, newMango12, newMango13, newMango14],
        img: newMango11,
        contraindications: <>
            <p>1. Индивидуальная непереносимость ингредиентов : В состав могут входить компоненты, такие как лактоза, глютен и орехи, которые могут вызвать непереносимость.</p>
            <p>2. Пациентам с хроническими заболеваниями : Людям с хроническими заболеваниями сердца, почек или печени следует проконсультироваться с врачом перед употреблением.</p>
            <p>3. Беременные и кормящие женщины : Употребление продукта должно быть согласовано с лечащим врачом.</p>
            <p>4. Маленькие дети : Продукт не предназначен для детей младше 3 лет.</p>
        </>
    },
    'protein-bars-without-glaze-chocolate-cake': {
        title: 'Протеиновые батончики без глазури Шоколадный торт',
        productType: 'Протеиновый батончик',
        description: (
            <>
                <p>Спортивный батончик без глазури от Japan Formula это диетическое питание без глютена для тех, кто занимается спортом и следит за своей фигурой. В составе идеальное соотношение КБЖУ, низкое количество углеводов и высокое содержание белка за счет наличия протеина. </p>
                <br />
                <p>Protein бар это нет только полезный низкокалорийный пп десерт, но и прекрасный белковый перекус в зале и фитнесе. Побалуйте себя шоколадными сладостями без вреда для здоровья. В коробке 16 штук, вес 1го батончика 40грамм. Допускается небольшое отклонение+-5грамма.</p>
            </>
        ),
        price: '900 ',
        tasteEng: 'Chocolate cake',
        taste: 'Шоколадный торт',
        energyValue: '154',
        nutritionalValueProteins: '13.2',
        nutritionalValueFats: '5.4',
        nutritionalValueCarbohydrates: '13.6',
        textPurpose: 'Восстановление, Выносливость, Красота и здоровье, Набор мышечной массы, Повышение работоспособности, Похудение/Сушка, Профилактика, Сила',
        purposes: ['здоровье кожи, волос и ногтей', 'энергия', 'восстановление', 'красота и здоровье'],
        composition: 'Изолят соевого белка, арахисовая мука, мальтодекстрин, вода, патока,; водорастворимое волокно кукурузы, сорбитол, кокосовое масло,; какао-порошок, глицерин, пищевые ароматизаторы, лецитин,; стевиозид, регулятор кислотности лимонная кислота,; сорбат калия, аскорбиновая кислота.',
        text: 'без глютена, без транс жиров, без ГМО',
        country: 'Россия',
        expirationDate: '12',
        storageConditions: 'В защищенном от солнца помещении, В прохладном темном месте, В сухом прохладном месте',
        gender: 'Женский, Мужской',
        images: [chocolateCake11, chocolateCake12, chocolateCake13, chocolateCake14],
        img: chocolateCake11,
        contraindications: <>
            <p>1. Индивидуальная непереносимость ингредиентов : В состав могут входить компоненты, такие как лактоза, глютен и орехи, которые могут вызвать непереносимость.</p>
            <p>2. Пациентам с хроническими заболеваниями : Людям с хроническими заболеваниями сердца, почек или печени следует проконсультироваться с врачом перед употреблением.</p>
            <p>3. Беременные и кормящие женщины : Употребление продукта должно быть согласовано с лечащим врачом.</p>
            <p>4. Маленькие дети : Продукт не предназначен для детей младше 3 лет.</p>
        </>
    },
    'protein-bars-without-glaze-raspberry-muffin': {
        title: 'Протеиновые батончики без глазури Малиновый маффин',
        productType: 'Протеиновый батончик',
        description: (
            <>
                <p>Спортивный батончик без глазури от Japan Formula это диетическое питание без глютена для тех, кто занимается спортом и следит за своей фигурой. Эти низкокалорийные сладости идеально подходят для взрослых, стремящихся к здоровому образу жизни. Каждый батончик содержит белок, способствуя поддержанию мышечной массы и эффективному похудению. </p>
                <br />
                <p>Protein бар это нет только полезный низкокалорийный пп десерт, но и прекрасный белковый перекус в зале и фитнесе. Побалуйте себя шоколадными сладостями без вреда для здоровья. В коробке 16 штук, вес 1го батончика 40грамм. Допускается небольшое отклонение+-5грамма.</p>
            </>
        ),
        price: '800 ',
        tasteEng: 'Raspberry Muffin',
        taste: 'Малиновый маффин',
        energyValue: '144',
        nutritionalValueProteins: '13.2',
        nutritionalValueFats: '3.3',
        nutritionalValueCarbohydrates: '16',
        textPurpose: 'Восстановление, Выносливость, Красота и здоровье, Набор мышечной массы, Повышение работоспособности, Похудение/Сушка, Профилактика, Сила',
        purposes: ['здоровье кожи, волос и ногтей', 'энергия', 'восстановление', 'красота и здоровье'],
        composition: 'Мальтодекстрин, изолят соевого белка, вода, патока,; сорбитол, кокосовое масло, водорастворимое волокно кукурузы; глицерин, пищевые ароматизаторы, лецитин, пищевой краситель,; евиозид, регулятор кислотности лимонная кислота,; сорбат калия, аскорбиновая кислота.',
        text: 'без антибиотиков, без ГМО, без глютена',
        country: 'Россия',
        expirationDate: '12',
        storageConditions: 'В защищенном от солнца помещении, В прохладном темном месте, В сухом прохладном месте',
        gender: 'Женский, Мужской',
        images: [raspberryMuffin11, raspberryMuffin12, raspberryMuffin13, raspberryMuffin14],
        img: raspberryMuffin11,
        contraindications: <>
            <p>1. Индивидуальная непереносимость ингредиентов : В состав могут входить компоненты, такие как лактоза, глютен и орехи, которые могут вызвать непереносимость.</p>
            <p>2. Пациентам с хроническими заболеваниями : Людям с хроническими заболеваниями сердца, почек или печени следует проконсультироваться с врачом перед употреблением.</p>
            <p>3. Беременные и кормящие женщины : Употребление продукта должно быть согласовано с лечащим врачом.</p>
            <p>4. Маленькие дети : Продукт не предназначен для детей младше 3 лет.</p>
        </>
    },
    'coconut-protein-bars-without-sugar': {
        title: 'Кокосовый протеиновый батончик. Без сахара.',
        productType: 'Энергетический батончик',
        description: (
            <>
                <p>Спортивный батончик с кокосом в шоколаде от Japan Formula это диетическое низкокалорийное питание без глютена для тех, кто занимается спортом и следит за своим весом. Оригинальный кокос. </p>
                <br />
                <p>В составе этой вкусняшки идеальное соотношение КБЖУ, низкое количество углеводов и высокое содержание белка за счет наличия протеина. Все это делает наш батончик очень полезным, низкоуглеводным и высокобелковым, что помогает похудеть и сбросить лишний вес.</p>
            </>
        ),
        price: '1300 ',
        tasteEng: 'coconut',
        taste: 'Оригинальный кокос',
        energyValue: '162',
        nutritionalValueProteins: '2.8',
        nutritionalValueFats: '11.2',
        nutritionalValueCarbohydrates: '3.5',
        textPurpose: 'Восстановление, Выносливость, Красота и здоровье, Набор мышечной массы, Повышение работоспособности, Похудение/Сушка, Профилактика, Сила',
        purposes: ['здоровье кожи, волос и ногтей', 'энергия', 'восстановление', 'красота и здоровье'],
        composition: 'Кокосовая стружка, мальтодекстрин, сироп олигофруктозы, глазурь кондитерская (Какао-порошок, заменитель какао-масла нетемперируемый лауринового типа, сухое обезжиренное молоко, подсластители — эритрит и сахара природного происхождения, ароматизатор, эмульгаторы, вкусоароматическое вещество «Ванилин»), сорбитол, водорастворимое волокно кукурузы, глицерин, ароматизатор, инулин, лимонная кислота, соль поваренная, сорбат калия, стевия, сукралоза, аскорбиновая кислота.',
        text: 'без глютена, без сахара, без ГМО',
        country: 'Россия',
        expirationDate: '24',
        storageConditions: 'В защищенном от солнца помещении, В прохладном темном месте, В сухом прохладном месте',
        gender: 'Женский, Мужской',
        images: [CPBC11, CPBC12, CPBC13, CPBC14],
        img: CPBC13,
        contraindications: <>
            <p>1. Индивидуальная непереносимость ингредиентов : В состав могут входить компоненты, такие как лактоза, глютен и орехи, которые могут вызвать непереносимость.</p>
            <p>2. Пациентам с хроническими заболеваниями : Людям с хроническими заболеваниями сердца, почек или печени следует проконсультироваться с врачом перед употреблением.</p>
            <p>3. Беременные и кормящие женщины : Употребление продукта должно быть согласовано с лечащим врачом.</p>
            <p>4. Маленькие дети : Продукт не предназначен для детей младше 3 лет.</p>
        </>
    },
    'coconut-protein-bars-without-sugar-strawberry-kiwi': {
        title: 'Кокосовый протеиновый батончик. Без сахара.',
        productType: 'Диетический батончик',
        description: (
            <>
                <p>Спортивный батончик с кокосом в шоколаде от Japan Formula это диетическое низкокалорийное питание без глютена для тех, кто занимается спортом и следит за своим весом. Клубника-киви. Кокос. </p>
                <br />
                <p>В составе этой вкусняшки идеальное соотношение КБЖУ, низкое количество углеводов и высокое содержание белка за счет наличия протеина. Все это делает наш батончик очень полезным, низкоуглеводным и высокобелковым, что помогает похудеть и сбросить лишний вес.</p>
            </>
        ),
        price: '1300 ',
        tasteEng: 'coconut strawberry kiwi',
        taste: 'Клубника-киви. Кокос',
        energyValue: '146',
        nutritionalValueProteins: '2.6',
        nutritionalValueFats: '11',
        nutritionalValueCarbohydrates: '4.1',
        textPurpose: 'Восстановление, Выносливость, Повышение работоспособности, Красота и здоровье, Набор мышечной массы, Похудение/Сушка, Профилактика, Сила',
        purposes: ['здоровье кожи, волос и ногтей', 'энергия', 'восстановление', 'красота и здоровье'],
        composition: ' Кокосовая стружка, сушеная клубника, сушеный киви, мальтодекстрин, сироп олигофруктозы, глазурь кондитерская (Какао-порошок, заменитель какао-масла нетемперируемый лауринового типа, сухое обезжиренное молоко, подсластители — эритрит и сахара природного происхождения, ароматизатор, эмульгаторы, вкусоароматическое вещество «Ванилин»), сорбитол, водорастворимое волокно кукурузы, глицерин, ароматизатор, инулин, лимонная кислота, соль поваренная, сорбат калия, стевия, сукралоза, аскорбиновая кислота.',
        text: 'без глютена, без сахара, без ГМО',
        country: 'Россия',
        expirationDate: '24',
        storageConditions: 'В защищенном от солнца помещении, В прохладном темном месте, В сухом прохладном месте',
        gender: 'Женский, Мужской',
        images: [CPBSK11, CPBSK12, CPBSK13, CPBSK14,],
        img: CPBSK13,
        contraindications: <>
            <p>1. Индивидуальная непереносимость ингредиентов : В состав могут входить компоненты, такие как лактоза, глютен и орехи, которые могут вызвать непереносимость.</p>
            <p>2. Пациентам с хроническими заболеваниями : Людям с хроническими заболеваниями сердца, почек или печени следует проконсультироваться с врачом перед употреблением.</p>
            <p>3. Беременные и кормящие женщины : Употребление продукта должно быть согласовано с лечащим врачом.</p>
            <p>4. Маленькие дети : Продукт не предназначен для детей младше 3 лет.</p>
        </>
    },
    'coconut-protein-bars-without-sugar-mango': {
        title: 'Кокосовый протеиновый батончик. Без сахара.',
        productType: 'Диетический батончик',
        description: (
            <>
                <p>Спортивный батончик с кокосом в шоколаде от Japan Formula это диетическое низкокалорийное питание без глютена для тех, кто занимается спортом и следит за своим весом. Манго. Кокос. </p>
                <br />
                <p>В составе этой вкусняшки идеальное соотношение КБЖУ, низкое количество углеводов и высокое содержание белка за счет наличия протеина. Все это делает наш батончик очень полезным, низкоуглеводным и высокобелковым, что помогает похудеть и сбросить лишний вес.</p>
            </>
        ),
        price: '1300 ',
        tasteEng: 'coconut  mango',
        taste: 'Манго. Кокос',
        energyValue: '159',
        nutritionalValueProteins: '2.7',
        nutritionalValueFats: '11',
        nutritionalValueCarbohydrates: '3.5',
        textPurpose: 'Восстановление, Выносливость, Красота и здоровье, Набор мышечной массы, Повышение работоспособности, Похудение/Сушка, Профилактика, Сила',
        purposes: ['здоровье кожи, волос и ногтей', 'энергия', 'восстановление', 'красота и здоровье'],
        composition: 'Кокосовая стружка, сушеное манго, мальтодекстрин, сироп олигофруктозы, глазурь кондитерская (Какао-порошок, заменитель какао-масла нетемперируемый лауринового типа, сухое обезжиренное молоко, подсластители — эритрит и сахара природного происхождения, ароматизатор, эмульгаторы, вкусоароматическое вещество «Ванилин»), сорбитол, водорастворимое волокно кукурузы,  глицерин, ароматизатор, инулин, лимонная кислота, соль поваренная, сорбат калия, стевия, сукралоза, аскорбиновая кислота.',
        text: 'без антибиотиков, без ГМО, без глутамата натрия, без глютена',
        country: 'Россия',
        expirationDate: '24',
        storageConditions: 'В защищенном от солнца помещении, В прохладном темном месте, В сухом прохладном месте',
        gender: 'Женский, Мужской',
        images: [CPBM11, CPBM12, CPBM13, CPBM14,],
        img: CPBM13,
        contraindications: <>
            <p>1. Индивидуальная непереносимость ингредиентов : В состав могут входить компоненты, такие как лактоза, глютен и орехи, которые могут вызвать непереносимость.</p>
            <p>2. Пациентам с хроническими заболеваниями : Людям с хроническими заболеваниями сердца, почек или печени следует проконсультироваться с врачом перед употреблением.</p>
            <p>3. Беременные и кормящие женщины : Употребление продукта должно быть согласовано с лечащим врачом.</p>
            <p>4. Маленькие дети : Продукт не предназначен для детей младше 3 лет.</p>
        </>
    },
    'coconut-protein-bars-without-sugar-chocolate': {
        title: 'Кокосовый протеиновый батончик. Без сахара.',
        productType: 'Энергетический батончик',
        description: (
            <>
                <p>Спортивный батончик с кокосом в шоколаде от Japan Formula это диетическое низкокалорийное питание без глютена для тех, кто занимается спортом и следит за своим весом. Двойной шоколад. Кокос. </p>
                <br />
                <p>В составе этой вкусняшки идеальное соотношение КБЖУ, низкое количество углеводов и высокое содержание белка за счет наличия протеина. Все это делает наш батончик очень полезным, низкоуглеводным и высокобелковым, что помогает похудеть и сбросить лишний вес.</p>
            </>
        ),
        price: '1300 ',
        tasteEng: 'coconut  chocolate',
        taste: 'Двойной шоколад. Кокос',
        energyValue: '160',
        nutritionalValueProteins: '3.2',
        nutritionalValueFats: '11',
        nutritionalValueCarbohydrates: '3.5',
        textPurpose: 'Восстановление, Выносливость, Красота и здоровье, Набор мышечной массы, Повышение работоспособности, Профилактика, Похудение/Сушка, Сила',
        purposes: ['здоровье кожи, волос и ногтей', 'энергия', 'восстановление', 'красота и здоровье'],
        composition: 'Кокосовая стружка, мальтодекстрин, сироп олигофруктозы, глазурь кондитерская (Какао-порошок, заменитель какао-масла нетемперируемый лауринового типа, сухое обезжиренное молоко, подсластители — эритрит и сахара природного происхождения, ароматизатор, эмульгаторы, вкусоароматическое вещество «Ванилин»), сорбитол, водорастворимое волокно кукурузы, сушеное манго, глицерин, ароматизатор, инулин, лимонная кислота, соль поваренная, сорбат калия, стевия, сукралоза, аскорбиновая кислота.',
        text: 'без ГМО, без глютена, без сахара',
        country: 'Россия',
        expirationDate: '24',
        storageConditions: 'В защищенном от солнца помещении, В прохладном темном месте, В сухом прохладном месте',
        gender: 'Женский, Мужской',
        images: [CPBCh11, CPBCh12, CPBCh13, CPBCh14,],
        img: CPBCh13,
        contraindications: <>
            <p>1. Индивидуальная непереносимость ингредиентов : В состав могут входить компоненты, такие как лактоза, глютен и орехи, которые могут вызвать непереносимость.</p>
            <p>2. Пациентам с хроническими заболеваниями : Людям с хроническими заболеваниями сердца, почек или печени следует проконсультироваться с врачом перед употреблением.</p>
            <p>3. Беременные и кормящие женщины : Употребление продукта должно быть согласовано с лечащим врачом.</p>
            <p>4. Маленькие дети : Продукт не предназначен для детей младше 3 лет.</p>
        </>
    },
    'coconut-protein-bars-without-mix': {
        title: 'Ассорти кокосовых протеиновых батончиков. Без сахара.',
        productType: 'Диетический батончик',
        description: (
            <>
                <p>Ассорти кокосовых протеиновых батончиков без сахара в шоколаде от Japan Formula это диетическое низкокалорийное питание без глютена для тех, кто занимается спортом и следит за своим весом.  Двойной шоколад. Оригинальный кокос. Клубника-Киви. Манго.</p>
                <br />
                <p>В составе этих вкусняшек идеальное соотношение КБЖУ, низкое количество углеводов и высокое содержание белка за счет наличия протеина. Все это делает наш батончик очень полезным, низкоуглеводным и высокобелковым, что помогает похудеть и сбросить лишний вес.</p>

            </>
        ),
        price: '1300 ',
        tasteEng: 'coconut  chocolate strawberry kiwi mango',
        taste: 'Микс четырех вкусов.',
        energyValue: 'Смотрите описание конкретного продукта',
        nutritionalValueProteins: 'Смотрите описание конкретного продукта',
        nutritionalValueFats: 'Смотрите описание конкретного продукта',
        nutritionalValueCarbohydrates: 'Смотрите описание конкретного продукта',
        textPurpose: 'Восстановление, Выносливость, Красота и здоровье, Набор мышечной массы, Повышение работоспособности, Похудение/Сушка, Профилактика, Сила',
        composition: 'Смотрите описание конкретного продукта.',
        text: 'без ГМО, без глютена, без сахара',
        purposes: ['стройность', 'лучшее для женщин', 'лучшее для мужчин'],
        country: 'Россия',
        expirationDate: '24',
        storageConditions: 'В сухом месте, В защищенном от солнца помещении',
        gender: 'Женский, Мужской',
        images: [CPBMix11, CPBMix12, CPBMix13, CPBMix14,],
        img: CPBMix13,
        contraindications: <>
            <p>1. Индивидуальная непереносимость ингредиентов : В состав могут входить компоненты, такие как лактоза, глютен и орехи, которые могут вызвать непереносимость.</p>
            <p>2. Пациентам с хроническими заболеваниями : Людям с хроническими заболеваниями сердца, почек или печени следует проконсультироваться с врачом перед употреблением.</p>
            <p>3. Беременные и кормящие женщины : Употребление продукта должно быть согласовано с лечащим врачом.</p>
            <p>4. Маленькие дети : Продукт не предназначен для детей младше 3 лет.</p>
        </>
    },
    'protein-bars-without-sugar-rocky-road': {
        title: 'Протеиновый батончик. Без сахара.',
        productType: 'Диетический батончик',
        description: (
            <>
                <p>Спортивный батончик в шоколаде от Japan Formula это диетическое низкокалорийное питание без глютена для тех, кто занимается спортом и следит за своим весом. </p>
                <p>Protein бар это нет только полезный низкокалорийный пп десерт, но и прекрасный белковый перекус в спорте и фитнесе. Побалуйте себя шоколадными сладостями без вреда для здоровья. Печенье Роки Роад </p>
            </>
        ),
        price: '1300 ',
        tasteEng: 'rocky road',
        taste: 'Печенье Роки Роад',
        energyValue: '101',
        nutritionalValueProteins: '13.2',
        nutritionalValueFats: '3.2',
        nutritionalValueCarbohydrates: '4.8',
        textPurpose: 'Восстановление, Выносливость, Красота и здоровье, Набор мышечной массы, Повышение работоспособности, Похудение/Сушка, Профилактика, Сила',
        purposes: ['здоровье кожи, волос и ногтей', 'энергия', 'восстановление', 'красота и здоровье'],
        composition: 'Изолят соевого белка, водорастворимое кукурузное волокно, концентрат сывороточного белка, изолят молочного белка, олигофруктоза, вода, клубника сушеная, какао порошок, кондитерская глазурь (какао порошок, какао масло, эквивалент масла какао), соевый лецитин (эмульгатор), ароматизатор (ванилин), кокосовое масло, сорбитол, глицерин, лимонная кислота, натуральные ароматизаторы, сорбат калия, стевиозид, лецитин, аскорбиновая кислота.',
        text: 'без ГМО, без глютена, без сахара',
        country: 'Россия',
        expirationDate: '24',
        storageConditions: 'В защищенном от солнца помещении.',
        gender: 'Женский, Мужской',
        images: [PBRR11, PBRR12, PBRR13, PBRR14],
        img: PBRR13,
        contraindications: <>
            <p>1. Индивидуальная непереносимость ингредиентов : В состав могут входить компоненты, такие как лактоза, глютен и орехи, которые могут вызвать непереносимость.</p>
            <p>2. Пациентам с хроническими заболеваниями : Людям с хроническими заболеваниями сердца, почек или печени следует проконсультироваться с врачом перед употреблением.</p>
            <p>3. Беременные и кормящие женщины : Употребление продукта должно быть согласовано с лечащим врачом.</p>
            <p>4. Маленькие дети : Продукт не предназначен для детей младше 3 лет.</p>
        </>
    },
    'protein-bars-without-sugar-strawberry-kiwi': {
        title: 'Протеиновый батончик. Без сахара.',
        productType: 'Диетический батончик',
        description: (
            <>
                <p>Спортивный батончик в шоколаде от Japan Formula это диетическое низкокалорийное питание без глютена для тех, кто занимается спортом и следит за своим весом. </p>
                <p>Protein бар это нет только полезный низкокалорийный пп десерт, но и прекрасный белковый перекус в спорте и фитнесе. Побалуйте себя шоколадными сладостями без вреда для здоровья. Клубника-киви.</p>
            </>
        ),
        price: '1300 ',
        tasteEng: 'strawberry kiwi',
        taste: 'Клубника-киви',
        energyValue: '261',
        nutritionalValueProteins: '13.2',
        nutritionalValueFats: '2.4',
        nutritionalValueCarbohydrates: '2.8',
        textPurpose: 'Восстановление, Выносливость, Красота и здоровье, Набор мышечной массы, Повышение работоспособности, Похудение/Сушка, Профилактика',
        purposes: ['здоровье кожи, волос и ногтей', 'энергия', 'восстановление', 'красота и здоровье'],
        composition: 'Изолят соевого белка, водорастворимое кукурузное волокно, концентрат сывороточного белка, изолят молочного белка, олигофруктоза, вода, клубника сушеная, киви сушеный, кондитерская глазурь (какао порошок, какао масло, эквивалент масла какао), соевый лецитин (эмульгатор), ароматизатор (ванилин), кокосовое масло, сорбитол, глицерин, лимонная кислота, натуральные ароматизаторы, сорбат калия, стевиозид, лецитин, аскорбиновая кислота.',
        text: 'без ГМО, без глютена',
        country: 'Россия',
        expirationDate: '24',
        storageConditions: 'В сухом месте, В защищенном от солнца помещении',
        gender: 'Женский, Мужской',
        images: [PBSK11, PBSK12, PBSK13, PBSK14,],
        img: PBSK13,
        contraindications: <>
            <p>1. Индивидуальная непереносимость ингредиентов : В состав могут входить компоненты, такие как лактоза, глютен и орехи, которые могут вызвать непереносимость.</p>
            <p>2. Пациентам с хроническими заболеваниями : Людям с хроническими заболеваниями сердца, почек или печени следует проконсультироваться с врачом перед употреблением.</p>
            <p>3. Беременные и кормящие женщины : Употребление продукта должно быть согласовано с лечащим врачом.</p>
            <p>4. Маленькие дети : Продукт не предназначен для детей младше 3 лет.</p>
        </>
    },
    'protein-bars-without-sugar-mango': {
        title: 'Протеиновый батончик. Без сахара.',
        productType: 'Энергетический батончик',
        description: (
            <>
                <p>Спортивный батончик в шоколаде от Japan Formula это диетическое низкокалорийное питание без глютена для тех, кто занимается спортом и следит за своим весом. </p>
                <p>Protein бар это нет только полезный низкокалорийный пп десерт, но и прекрасный белковый перекус в спорте и фитнесе. Побалуйте себя шоколадными сладостями без вреда для здоровья. Манго.</p>
            </>
        ),
        price: '1300 ',
        tasteEng: 'mango',
        taste: 'Манго',
        energyValue: '70.4',
        nutritionalValueProteins: '13.2',
        nutritionalValueFats: '1.2',
        nutritionalValueCarbohydrates: '2.8',
        textPurpose: 'Восстановление, Выносливость, Красота и здоровье, Набор мышечной массы, Повышение работоспособности, Похудение/Сушка, Профилактика, Сила',
        purposes: ['здоровье кожи, волос и ногтей', 'энергия', 'восстановление', 'красота и здоровье'],
        composition: 'Изолят соевого белка, водорастворимое кукурузное волокно, манго сушеный, концентрат сывороточного белка, изолят молочного белка, олигофруктоза, вода, кондитерская глазурь (Какао порошок, какао масло, эквивалент масла какао), соевый лецитин (эмульгатор), ароматизатор (ванилин), кокосовое масло, сорбитол, глицерин, лимонная кислота, натуральные ароматизаторы, сорбат калия, стевиозид, лецитин, аскорбиновая кислота.',
        text: 'без ГМО, без глютена, без сахара',
        country: 'Россия',
        expirationDate: '24',
        storageConditions: 'В защищенном от солнца помещении, В прохладном темном месте, В сухом прохладном месте',
        gender: 'Женский, Мужской',
        images: [PBM11, PBM12, PBM13, PBM14,],
        img: PBM13,
        contraindications: <>
            <p>1. Индивидуальная непереносимость ингредиентов : В состав могут входить компоненты, такие как лактоза, глютен и орехи, которые могут вызвать непереносимость.</p>
            <p>2. Пациентам с хроническими заболеваниями : Людям с хроническими заболеваниями сердца, почек или печени следует проконсультироваться с врачом перед употреблением.</p>
            <p>3. Беременные и кормящие женщины : Употребление продукта должно быть согласовано с лечащим врачом.</p>
            <p>4. Маленькие дети : Продукт не предназначен для детей младше 3 лет.</p>
        </>
    },
    'protein-bars-without-sugar-chocolate': {
        title: 'Протеиновый батончик. Без сахара.',
        productType: 'Энергетический батончик',
        description: (
            <>
                <p>Спортивный батончик в шоколаде от Japan Formula это диетическое низкокалорийное питание без глютена для тех, кто занимается спортом и следит за своим весом. </p>
                <p>Protein бар это нет только полезный низкокалорийный пп десерт, но и прекрасный белковый перекус в спорте и фитнесе. Побалуйте себя шоколадными сладостями без вреда для здоровья. Шоколад.</p>
            </>
        ),
        price: '1300 ',
        tasteEng: 'chocolate',
        taste: 'Шоколад.',
        energyValue: '326',
        nutritionalValueProteins: '13.2',
        nutritionalValueFats: '3.8',
        nutritionalValueCarbohydrates: '4',
        textPurpose: 'Восстановление, Выносливость, Красота и здоровье, Набор мышечной массы, Повышение работоспособности, Похудение/Сушка, Профилактика, Сила',
        purposes: ['здоровье кожи, волос и ногтей', 'энергия', 'восстановление', 'красота и здоровье'],
        composition: 'Изолят соевого белка, водорастворимое кукурузное волокно, концентрат сывороточного белка, изолят молочного белка, олигофруктоза, вода, какао порошок, кондитерская глазурь (какао порошок, какао масло, эквивалент масла какао), соевый лецитин (эмульгатор), ароматизатор (ванилин), кокосовое масло, сорбитол, глицерин, лимонная кислота, натуральные ароматизаторы, сорбат калия, стевиозид, лецитин, аскорбиновая кислота.',
        text: 'без ГМО, без глютена, без сахара',
        country: 'Россия',
        expirationDate: '24',
        storageConditions: 'В сухом месте, В защищенном от солнца помещении',
        gender: 'Женский, Мужской',
        images: [PBCh11, PBCh12, PBCh13, PBCh14,],
        img: PBCh13,
        contraindications: <>
            <p>1. Индивидуальная непереносимость ингредиентов : В состав могут входить компоненты, такие как лактоза, глютен и орехи, которые могут вызвать непереносимость.</p>
            <p>2. Пациентам с хроническими заболеваниями : Людям с хроническими заболеваниями сердца, почек или печени следует проконсультироваться с врачом перед употреблением.</p>
            <p>3. Беременные и кормящие женщины : Употребление продукта должно быть согласовано с лечащим врачом.</p>
            <p>4. Маленькие дети : Продукт не предназначен для детей младше 3 лет.</p>
        </>
    },
    'protein-bars-without-mix': {
        title: 'Ассорти протеиновых батончиков. Без сахара.',
        productType: 'Энергетический батончик',
        description: (
            <>
                <p>Ассорти протеиновых батончиков. Шоколад. Печенье Роки Роад. Клубника-Киви. Манго. </p>
                <p>Спортивный батончик в шоколаде от Japan Formula это диетическое низкокалорийное питание без глютена для тех, кто занимается спортом и следит за своим весом. </p>
                <p>Protein бар это нет только полезный низкокалорийный пп десерт, но и прекрасный белковый перекус в спорте и фитнесе. Побалуйте себя шоколадными сладостями без вреда для здоровья</p>
            </>
        ),
        price: '1300 ',
        tasteEng: 'chocolate rocky road strawberry kiwi mango',
        taste: 'Микс четырех вкусов.',
        energyValue: 'Смотрите описание конкретного продукта',
        nutritionalValueProteins: 'Смотрите описание конкретного продукта',
        nutritionalValueFats: 'Смотрите описание конкретного продукта',
        nutritionalValueCarbohydrates: 'Смотрите описание конкретного продукта',
        textPurpose: 'Восстановление, Выносливость, Красота и здоровье, Набор мышечной массы, Повышение работоспособности, Похудение/Сушка, Профилактика, Сила',
        composition: 'Смотрите описание конкретного продукта.',
        text: 'без ГМО, без глютена, без сахара',
        purposes: ['стройность', 'лучшее для женщин', 'лучшее для мужчин'],
        country: 'Россия',
        expirationDate: '24',
        storageConditions: 'В сухом месте, В защищенном от солнца помещении',
        gender: 'Женский, Мужской',
        images: [PBMix11, PBMix12, PBMix13, PBMix14,],
        img: PBMix13,
        contraindications: <>
            <p>1. Индивидуальная непереносимость ингредиентов : В состав могут входить компоненты, такие как лактоза, глютен и орехи, которые могут вызвать непереносимость.</p>
            <p>2. Пациентам с хроническими заболеваниями : Людям с хроническими заболеваниями сердца, почек или печени следует проконсультироваться с врачом перед употреблением.</p>
            <p>3. Беременные и кормящие женщины : Употребление продукта должно быть согласовано с лечащим врачом.</p>
            <p>4. Маленькие дети : Продукт не предназначен для детей младше 3 лет.</p>
        </>
    },
    'protein-bars-mix': {
        title: 'Протеиновый батончик. Без сахара.',
        productType: 'Энергетический батончик',
        description: (
            <>
                <p>Спортивный батончик в шоколаде от Japan Formula это диетическое низкокалорийное питание без глютена для тех, кто занимается спортом и следит за своим весом. </p>
                <br />
                <p>В составе этих вкусняшек идеальное соотношение КБЖУ, низкое количество углеводов и высокое содержание белка за счет наличия протеина. Все это делает наш батончик очень полезным, низкоуглеводным и высокобелковым, что помогает похудеть и сбросить лишний вес.</p>
            </>
        ),
        price: '1450 ',
        tasteEng: 'pistachio nuts berries peanut caramel',
        taste: 'Микс четырех вкусов.',
        energyValue: 'Смотрите описание конкретного продукта',
        nutritionalValueProteins: 'Смотрите описание конкретного продукта',
        nutritionalValueFats: 'Смотрите описание конкретного продукта',
        nutritionalValueCarbohydrates: 'Смотрите описание конкретного продукта',
        textPurpose: 'Восстановление, Для набора мышечной массы; Энергетический; диетический',
        purposes: ['стройность', 'лучшее для женщин', 'лучшее для мужчин'],
        composition: 'Изолят соевого белка, водорастворимое кукурузное волокно, концентрат сывороточного белка, изолят молочного белка, олигофруктоза, вода, какао порошок, кондитерская глазурь (какао порошок, какао масло, эквивалент масла какао), соевый лецитин (эмульгатор), ароматизатор (ванилин), кокосовое масло, сорбитол, глицерин, лимонная кислота, натуральные ароматизаторы, сорбат калия, стевиозид, лецитин, аскорбиновая кислота.',
        text: 'Добавки:	Белки; витамины; клетчатка',
        country: 'Россия',
        expirationDate: '24',
        storageConditions: 'В сухом месте, В защищенном от солнца помещении',
        gender: 'Женский, Мужской',
        images: [mix11, mix12, mix14, mix13,],
        img: mix13,
        contraindications: <>
            <p>1. Индивидуальная непереносимость ингредиентов : В состав могут входить компоненты, такие как лактоза, глютен и орехи, которые могут вызвать непереносимость.</p>
            <p>2. Пациентам с хроническими заболеваниями : Людям с хроническими заболеваниями сердца, почек или печени следует проконсультироваться с врачом перед употреблением.</p>
            <p>3. Беременные и кормящие женщины : Употребление продукта должно быть согласовано с лечащим врачом.</p>
            <p>4. Маленькие дети : Продукт не предназначен для детей младше 3 лет.</p>
        </>
    },
    'protein-bars-pistachio': {
        title: 'Протеиновый батончик. Без сахара.',
        productType: 'Энергетический батончик',
        description: (
            <>
                <p>Спортивный батончик в шоколаде от Japan Formula это диетическое низкокалорийное питание без глютена для тех, кто занимается спортом и следит за своим весом. </p>
                <br />
                <p>В составе этой вкусняшки идеальное соотношение КБЖУ, низкое количество углеводов и высокое содержание белка за счет наличия протеина. Все это делает наш батончик очень полезным, низкоуглеводным и высокобелковым, что помогает похудеть и сбросить лишний вес.</p>
            </>
        ),
        price: '1450 ',
        tasteEng: 'pistachio nuts',
        taste: 'Фисташка.',
        energyValue: '319',
        nutritionalValueProteins: '13.2',
        nutritionalValueFats: '6.5',
        nutritionalValueCarbohydrates: '4',
        textPurpose: 'Для набора мышечной массы; Энергетический; диетический',
        purposes: ['стройность', 'лучшее для женщин', 'лучшее для мужчин'],
        composition: 'Мальтодекстрин , изолят соевого белка, глазурь кондитерская (мальтит,; заменитель какао масла лауринового типа, сухая молочная сыворотка,; Сухое обезжиренное молоко ,какао порошок, эмульгатор е322, ароматизатор ванилин, стевеозид),; Декстрин, вода , карамельная патока , Сорбитол, кокосовое масло,; Глицерин , ароматизатор, Манго сушеное, лецитин, краситель,Стевиозид,; лимонная кислота, сорбат кальция, аскорбиновая кислота',
        country: 'Россия',
        text: 'Добавки:	Белки; витамины; клетчатка',
        expirationDate: '24',
        storageConditions: 'В сухом месте, В защищенном от солнца помещении',
        gender: 'Женский, Мужской',
        images: [pistachio11, pistachio12, pistachio13, nuts01,],
        img: pistachio11,
        contraindications: <>
            <p>1. Индивидуальная непереносимость ингредиентов : В состав могут входить компоненты, такие как лактоза, глютен и орехи, которые могут вызвать непереносимость.</p>
            <p>2. Пациентам с хроническими заболеваниями : Людям с хроническими заболеваниями сердца, почек или печени следует проконсультироваться с врачом перед употреблением.</p>
            <p>3. Беременные и кормящие женщины : Употребление продукта должно быть согласовано с лечащим врачом.</p>
            <p>4. Маленькие дети : Продукт не предназначен для детей младше 3 лет.</p>
        </>
    },
    'protein-bars-nuts': {
        title: 'Протеиновый батончик. Без сахара.',
        productType: 'Энергетический батончик',
        description: (
            <>
                <p>Спортивный батончик в шоколаде от Japan Formula это диетическое низкокалорийное питание без глютена для тех, кто занимается спортом и следит за своим весом. </p>
                <br />
                <p>В составе этой вкусняшки идеальное соотношение КБЖУ, низкое количество углеводов и высокое содержание белка за счет наличия протеина. Все это делает наш батончик очень полезным, низкоуглеводным и высокобелковым, что помогает похудеть и сбросить лишний вес.</p>
            </>
        ),
        price: '1450 ',
        tasteEng: 'nuts',
        taste: 'Смесь орехов.',
        energyValue: '319',
        nutritionalValueProteins: '13.2',
        nutritionalValueFats: '6.5',
        nutritionalValueCarbohydrates: '4',
        textPurpose: 'Для набора мышечной массы; Энергетический; диетический',
        purposes: ['стройность', 'лучшее для женщин', 'лучшее для мужчин', 'здоровье кожи, волос и ногтей',],
        composition: 'Мальтодекстрин , изолят соевого белка, глазурь кондитерская (мальтит,; заменитель какао масла лауринового типа, сухая молочная сыворотка,; Сухое обезжиренное молоко ,какао порошок, эмульгатор е322, ароматизатор ванилин, стевеозид),; Декстрин, вода , карамельная патока , Сорбитол, кокосовое масло,; Глицерин , ароматизатор, Манго сушеное, лецитин, краситель,Стевиозид,; лимонная кислота, сорбат кальция, аскорбиновая кислота',
        country: 'Россия',
        text: 'Добавки:	Белки; витамины; клетчатка',
        expirationDate: '24',
        storageConditions: 'В сухом месте, В защищенном от солнца помещении',
        gender: 'Женский, Мужской',
        images: [nuts11, nuts12, nuts13, nuts01,],
        img: nuts11,
        contraindications: <>
            <p>1. Индивидуальная непереносимость ингредиентов : В состав могут входить компоненты, такие как лактоза, глютен и орехи, которые могут вызвать непереносимость.</p>
            <p>2. Пациентам с хроническими заболеваниями : Людям с хроническими заболеваниями сердца, почек или печени следует проконсультироваться с врачом перед употреблением.</p>
            <p>3. Беременные и кормящие женщины : Употребление продукта должно быть согласовано с лечащим врачом.</p>
            <p>4. Маленькие дети : Продукт не предназначен для детей младше 3 лет.</p>
        </>
    },
    'protein-bars-peanut-caramel': {
        title: 'Протеиновый батончик. Без сахара.',
        productType: 'Энергетический батончик',
        description: (
            <>
                <p>Спортивный батончик в шоколаде от Japan Formula это диетическое низкокалорийное питание без глютена для тех, кто занимается спортом и следит за своим весом. </p>
                <br />
                <p>В составе этой вкусняшки идеальное соотношение КБЖУ, низкое количество углеводов и высокое содержание белка за счет наличия протеина. Все это делает наш батончик очень полезным, низкоуглеводным и высокобелковым, что помогает похудеть и сбросить лишний вес.</p>
            </>
        ),
        price: '1450 ',
        tasteEng: 'nuts peanut caramel',
        taste: 'Арахис. Карамель.',
        energyValue: '319',
        nutritionalValueProteins: '13.2',
        nutritionalValueFats: '6.5',
        nutritionalValueCarbohydrates: '4',
        textPurpose: 'Для набора мышечной массы; Энергетический; диетический',
        purposes: ['стройность', 'лучшее для женщин', 'лучшее для мужчин', 'здоровье кожи, волос и ногтей',],
        composition: 'Мальтодекстрин , изолят соевого белка, глазурь кондитерская (мальтит,; заменитель какао масла лауринового типа, сухая молочная сыворотка,; Сухое обезжиренное молоко ,какао порошок, эмульгатор е322, ароматизатор ванилин, стевеозид),; Декстрин, вода , карамельная патока , Сорбитол, кокосовое масло,; Глицерин , ароматизатор, Манго сушеное, лецитин, краситель,Стевиозид,; лимонная кислота, сорбат кальция, аскорбиновая кислота',
        country: 'Россия',
        text: 'Добавки:	Белки; витамины; клетчатка',
        expirationDate: '24',
        storageConditions: 'В сухом месте, В защищенном от солнца помещении',
        gender: 'Женский, Мужской',
        images: [caramel11, caramel12, caramel13, nuts01,],
        img: caramel11,
        contraindications: <>
            <p>1. Индивидуальная непереносимость ингредиентов : В состав могут входить компоненты, такие как лактоза, глютен и орехи, которые могут вызвать непереносимость.</p>
            <p>2. Пациентам с хроническими заболеваниями : Людям с хроническими заболеваниями сердца, почек или печени следует проконсультироваться с врачом перед употреблением.</p>
            <p>3. Беременные и кормящие женщины : Употребление продукта должно быть согласовано с лечащим врачом.</p>
            <p>4. Маленькие дети : Продукт не предназначен для детей младше 3 лет.</p>
        </>
    },
    'protein-bars-berries': {
        title: 'Протеиновый батончик. Без сахара.',
        productType: 'Энергетический батончик',
        description: (
            <>
                <p>Спортивный батончик в шоколаде от Japan Formula это диетическое низкокалорийное питание без глютена для тех, кто занимается спортом и следит за своим весом. </p>
                <br />
                <p>В составе этой вкусняшки идеальное соотношение КБЖУ, низкое количество углеводов и высокое содержание белка за счет наличия протеина. Все это делает наш батончик очень полезным, низкоуглеводным и высокобелковым, что помогает похудеть и сбросить лишний вес.</p>
            </>
        ),
        price: '1450 ',
        tasteEng: 'berries',
        taste: 'Дикие ягоды.',
        energyValue: '319',
        nutritionalValueProteins: '13.2',
        nutritionalValueFats: '6.5',
        nutritionalValueCarbohydrates: '4',
        textPurpose: 'Для набора мышечной массы; Энергетический; диетический',
        purposes: ['стройность', 'лучшее для женщин', 'лучшее для мужчин', 'здоровье кожи, волос и ногтей',],
        composition: 'Мальтодекстрин , изолят соевого белка, глазурь кондитерская (мальтит,; заменитель какао масла лауринового типа, сухая молочная сыворотка,; Сухое обезжиренное молоко ,какао порошок, эмульгатор е322, ароматизатор ванилин, стевеозид),; Декстрин, вода , карамельная патока , Сорбитол, кокосовое масло,; Глицерин , ароматизатор, Манго сушеное, лецитин, краситель,Стевиозид,; лимонная кислота, сорбат кальция, аскорбиновая кислота',
        country: 'Россия',
        text: 'Добавки:	Белки; витамины; клетчатка',
        expirationDate: '24',
        storageConditions: 'В сухом месте, В защищенном от солнца помещении',
        gender: 'Женский, Мужской',
        images: [berries11, berries12, berries13, nuts01,],
        img: berries11,
        contraindications: <>
            <p>1. Индивидуальная непереносимость ингредиентов : В состав могут входить компоненты, такие как лактоза, глютен и орехи, которые могут вызвать непереносимость.</p>
            <p>2. Пациентам с хроническими заболеваниями : Людям с хроническими заболеваниями сердца, почек или печени следует проконсультироваться с врачом перед употреблением.</p>
            <p>3. Беременные и кормящие женщины : Употребление продукта должно быть согласовано с лечащим врачом.</p>
            <p>4. Маленькие дети : Продукт не предназначен для детей младше 3 лет.</p>
        </>
    },
}