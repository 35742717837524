import slimness from "../images/slimness.webp";
import detox from "../images/detox.webp";
import hair from "../images/hair.webp";
import energy from "../images/energy.webp";
import calmness from "../images/calmness.webp";
import recovery from "../images/recovery.webp";
import beauty from "../images/beauty.webp";
import forWomen from "../images/forWomen.webp";
import forMen from "../images/forMen.webp";

export const cardsData = [
  {
    img: slimness, alt: "Ваша цель стройность", goal: 'стройность', purpose: "slimness", description: (
      <>
        <p>Достичь стройности - это не просто путь к красоте, но и к здоровью и уверенности в себе. Воспользуйтесь нашими продуктами, чтобы шаг за шагом приближаться к своей цели и наслаждаться каждым моментом этого пути! </p>
      </>
    ),
  },
  {
    img: detox, alt: "Ваша цель детокс", goal: 'детокс, очищение', purpose: "cleansing", description: (
      <>
        <p>Очистите свой организм и почувствуйте прилив новой энергии! Наши детокс-продукты помогут вам избавиться от токсинов, улучшить самочувствие и раскрыть внутреннюю силу.</p>
      </>
    ),
  },
  {
    img: hair, alt: "Ваша цель здоровье кожи, волос и ногтей", goal: 'здоровье кожи, волос и ногтей', purpose: "hair", description: (
      <>
        <p>Красота начинается изнутри. Позаботьтесь о своей коже, волосах и ногтях с помощью наших продуктов, разработанных специально для поддержания вашего здоровья и естественного сияния</p>
      </>
    ),
  },
  {
    img: energy, alt: "Ваша цель энергия", goal: 'энергия', purpose: "energy", description: (
      <>
        <p>Наполните свой день энергией и бодростью! Наши продукты помогут вам оставаться активными, сосредоточенными и готовыми к любым вызовам.</p>
      </>
    ),
  },
  {
    img: calmness, alt: "Ваша цель спокойствие", goal: 'спокойствие', purpose: "calmness", description: (
      <>
        <p>В суете повседневной жизни так важно найти моменты спокойствия и расслабления. Наши продукты помогут вам обрести гармонию и внутренний покой, чтобы вы могли наслаждаться каждым мгновением</p>
      </>
    ),
  },
  {
    img: recovery, alt: "Ваша цель восстановление", goal: 'восстановление', purpose: "recovery", description: (
      <>
        <p>Ваше тело заслуживает лучшего восстановления после интенсивных тренировок и долгих дней. Наши продукты поддержат ваше здоровье, помогая быстрее восстанавливаться и готовиться к новым достижениям</p>
      </>
    ),
  },
  {
    img: beauty, alt: "Ваша цель красота", goal: 'красота и здоровье', purpose: "beauty", description: (
      <>
        <p>Красота и здоровье - это гармония внешнего и внутреннего состояния. Воспользуйтесь нашими продуктами, чтобы подчеркнуть свою природную красоту и укрепить здоровье</p>
      </>
    ),
  },
  {
    img: forWomen, alt: "Лучшее для женщин", goal: 'лучшее для женщин', purpose: "forWomen", description: (
      <>
        <p>Будьте в лучшей форме и почувствуйте себя великолепно каждый день. Наши продукты специально разработаны для поддержания женского здоровья и красоты, чтобы вы могли сиять изнутри</p>
      </>
    ),
  },
  {
    img: forMen, alt: "Лучшее для мужчин", goal: 'лучшее для мужчин', purpose: "forMen", description: (
      <>
        <p>Будьте сильными и энергичными, готовыми к любым вызовам. Наши продукты помогут вам поддерживать отличную физическую форму и здоровье, чтобы вы могли достигать новых высот.</p>
      </>
    ),
  },
];
