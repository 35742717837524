import React, { useEffect } from "react";

import "./Cooperation.css";
import telegram from "../../images/telegram.png";

const Cooperation = ({ handleTelegramClickSupport}) => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <section className="cooperation" id="cooperation">
      <div className="cooperation__wrapper">
        <h2 className="cooperation__title">
        🎥 Ты блогер? 
        </h2>
        <h3 className="cooperation__subtitle">
        Давай делать крутой контент вместе! 🌟
        <br />
        Мы ищем амбассадоров, которые готовы тестировать и рассказывать о наших продуктах.
        <br />
        💡 Готовы к взаимовыгодному сотрудничеству!
        </h3>
        <div className="cooperation__block">
        <div className="cooperation__telegram-content">
         
          <img
            src={telegram}
            alt="Иконка телеграмм"
            onClick={handleTelegramClickSupport}
          />
        </div>
        <p className="cooperation__subtitle"> Напишите нам в Telegram и получите эксклюзивные условия! 📩</p>
      </div>
      </div>
    </section>
  );
};

export default Cooperation;
