import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

import PageHelmet from "../PageHelmet";

import { proteinBarsProducts } from "./../ProteinBars/proteinBarsProducts.js";
import { proteinShakesProducts } from "./../ProteinShakes/proteinShakesProducts.js";
import { collagenProducts } from "./../Collagen/collagenProducts.js";
import { fatBurnersProducts } from "./../FatBurners/fatBurnersProducts.js";
import { detoxProducts } from "./../Detox/detoxProducts.js";
import { vitaminsProducts } from "./../Vitamins/vitaminsProducts.js";
import "./ChooseTaste.css";
import products from "../../images/imgTaste.png";

const productDatabases = {
  "protein-bars": proteinBarsProducts,
  "protein-shakes": proteinShakesProducts,
  collagen: collagenProducts,
  "fat-burners": fatBurnersProducts,
  detox: detoxProducts,
  vitamins: vitaminsProducts,
};

const ChooseTaste = ({ headerHeight, handleGoBack, handleGoStore }) => {
  const { taste } = useParams();
  const navigate = useNavigate();

  const filteredProducts = Object.values(productDatabases)
    .flatMap((db) => Object.values(db)) // flatMap to handle object values
    .filter(
      (product) =>
        product.tasteEng &&
        product.tasteEng.toLowerCase().includes(taste.toLowerCase())
    );

  const handleProductClick = (productId, productKey) => {
    navigate(`/products/${productId}/${productKey}`);
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const pageTitle = `Продукты со вкусом: ${
    taste.charAt(0).toUpperCase() + taste.slice(1)
  }`;
  const pageSubtitle = `Попробуй продукт со своим любимым вкусом: ${
    taste.charAt(0).toUpperCase() + taste.slice(1)
  }`;
  const pageDescription = `Откройте для себя наши продукты со вкусом ${taste}. В нашем ассортименте вы найдете лучшие товары для достижения ваших целей.`;
  const pageImage =
    filteredProducts.length > 0 ? filteredProducts[0].img : products;

    const structuredData = {
      "@context": "https://schema.org",
      "@type": "CollectionPage",
      name: `Продукты со вкусом ${taste}`,
      description: `Подборка продуктов Japan Formula со вкусом ${taste}.`,
      hasPart: filteredProducts.map((product) => {
        const productId = Object.keys(productDatabases).find((key) =>
          Object.values(productDatabases[key]).includes(product)
        );
        const productKey = Object.keys(productDatabases[productId]).find(
          (key) => productDatabases[productId][key] === product
        );
    
        return {
          "@type": "Product",
          name: product.name,
          image: product.img,
          url: `https://japanformula.com/products/${productId}/${productKey}`,
        };
      }),
    };    

  return (
    <div className="choose-taste" style={{ paddingTop: `${headerHeight}px` }}>
            <PageHelmet
        title={`Продукты со вкусом ${taste} - Japan Formula`}
        description={`Все продукты Japan Formula со вкусом ${taste}. Выберите свой!`}
        canonicalUrl={`https://japanformula.com/choose-taste/${taste}`}
        structuredData={structuredData}
        ogType="website"
      />
      <div className="choose-taste__wrapper">
        <div className="products__image-container">
          <img className="products__img" src={products} alt="Плакат фирмы" />
        </div>
        <div className="about-us__button-block">
          <button
            className="about-us__button-main privacy-policy__button"
            type="button"
            onClick={handleGoBack}
          >
            ВСЕ ВКУСЫ
          </button>
          <button
            className="about-us__button-main privacy-policy__button"
            type="button"
            onClick={handleGoStore}
          >
            Где купить
          </button>
        </div>
        <h1 className="choose-taste__title">{pageSubtitle}</h1>
        <div className="choose-taste__block-container">
          <ul className="choose-taste__list">
            {filteredProducts.length > 0 ? (
              filteredProducts.map((product, index) => {
                const productId = Object.keys(productDatabases).find((key) =>
                  Object.values(productDatabases[key]).includes(product)
                );
                const productKey = Object.keys(
                  productDatabases[productId]
                ).find((key) => productDatabases[productId][key] === product);

                return (
                  <li
                    key={index}
                    className="choose-taste__item"
                    onClick={() => handleProductClick(productId, productKey)}
                  >
                    <img
                      src={product.img}
                      alt={product.title}
                      className="choose-taste__image"
                    />
                    <div className="choose-taste__product-content">
                      <h2 className="choose-taste__product-title">
                        {product.title}
                      </h2>
                      <div className="choose-taste__product-name">
                        {product.taste}
                      </div>
                    </div>
                  </li>
                );
              })
            ) : (
              <p>Нет продуктов с таким вкусом.</p>
            )}
          </ul>
        </div>
        <button
          className="choose-taste__button-main privacy-policy__button"
          type="button"
          onClick={() => navigate(-1)}
        >
          ВСЕ ВКУСЫ
        </button>
      </div>
    </div>
  );
};

export default ChooseTaste;
