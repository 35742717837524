import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import "./NavTab.css";
import Dropdown from "../Dropdown/Dropdown";

import closeIcon from "../../images/closeIcon.svg";

const NavTab = ({ handleBurgerButtonClick, isMobile, headerHeight }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const navigate = useNavigate();

  const navItems = [
    {
      label: "Кто мы",
      path: "/about/",
      content: [
        { item: "О НАС", id: "about-us" },
        { item: "Почему выбирают JAPAN FORMULA", id: "choose-us" },
        { item: "Наши ценности", id: "values" },
        { item: "Телеграм и ВКонтакте", id: "social-media" },
        { item: "Сотрудничество", id: "cooperation" },
      ],
    },
    {
      label: "Наши продукты",
      path: "/products/",
      id: "productsnews",
      content: [
        { item: "КОЛЛАГЕН", id: "collagen" },
        { item: "ПРОТЕИНОВЫЕ БАТОНЧИКИ", id: "protein-bars" },
        { item: "ПРОТЕИНОВЫЕ КОКТЕЙЛИ", id: "protein-shakes" },
        { item: "Жиросжигатели", id: "fat-burners" },
        { item: "Детокс", id: "detox" },
        { item: "Витамины", id: "vitamins" },
      ],
    },
    {
      label: "Центр знаний",
      path: "/scientific-center/",
      id: "scientific-center",
    },
    {
      label: "Где купить",
      path: "/store/",
      id: "store",
    },
  ];

  const handleItemHover = (index) => {
    setIsModalOpen(true);
    setSelectedItem(navItems[index]);
  };

  const handleItemToggle = (index) => {
    setIsModalOpen(!isModalOpen);
    setSelectedItem(navItems[index]);
  };

  const handleItemClick = (path, sectionId) => {
    setIsModalOpen(false);

    if (path === "/products/" && sectionId) {
      navigate(`${path}${sectionId}/`);
    } else {
      navigate(path);
    }

    setTimeout(() => {
      if (sectionId) {
        const element = document.getElementById(sectionId);
        if (element) {
          const elementOffset = element.offsetTop - headerHeight;
          window.scrollTo({
            top: elementOffset,
            behavior: "smooth",
          });
        }
      }
    }, 0);
  };

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (isModalOpen && e.target.closest(".nav-tab__wrapper") === null) {
        setIsModalOpen(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [isModalOpen]);

  const handleOverlay = (e) => {
    if (e.target === e.currentTarget) {
      handleBurgerButtonClick();
    }
  };

  return (
    <nav
      className={`nav-tab ${isMobile ? "non-mobile-style" : ""}`}
      onClick={handleOverlay}
    >
      <div className="nav-tab__wrapper">
        <ul className="nav-tab__list">
          {navItems.map((item, index) => (
            <li
              key={index}
              className="nav-tab__item "
              onMouseEnter={() => handleItemHover(index)}
              onClick={() => handleItemToggle(index)}
            >
              <div className="nav-tab__item-container">
                {item.content ? (
                  <button
                    className="nav-tab__link"
                    onClick={() => handleItemClick(item.path, null)}
                  >
                    {item.label}
                  </button>
                ) : (
                  <button
                    className="nav-tab__link"
                    onClick={() => {
                      navigate(item.path);
                      if (
                        isMobile &&
                        typeof handleBurgerButtonClick === "function"
                      ) {
                        handleBurgerButtonClick();
                      }
                    }}
                  >
                    {item.label}
                  </button>
                )}
              </div>
              {isModalOpen &&
                selectedItem &&
                selectedItem.label === item.label && (
                  <Dropdown
                    content={selectedItem.content}
                    onItemClick={(sectionId) =>
                      handleItemClick(item.path, sectionId)
                    }
                    onMouseEnter={() => setIsModalOpen(true)}
                    onMouseLeave={() => setIsModalOpen(false)}
                    handleBurgerButtonClick={handleBurgerButtonClick}
                    isMobile={isMobile}
                  />
                )}
            </li>
          ))}
        </ul>
        <button
          className={`${
            isMobile ? "nav-tab__close-burger" : "nav-tab__hidden"
          }`}
        >
          <img
            src={closeIcon}
            alt="Иконка закрытия бургер меню"
            onClick={handleBurgerButtonClick}
          />
        </button>
      </div>
    </nav>
  );
};

export default NavTab;
