// BaseHelmet.jsx
import React from "react";
import { Helmet } from "react-helmet-async";

const BaseHelmet = () => (
  <Helmet>
    <meta name="author" content="Japan Formula" />
    <meta
      name="copyright"
      content={`Copyright © ${new Date().getFullYear()} Japan Formula`}
    />
    <meta property="og:type" content="website" />
    <meta property="og:locale" content="ru_RU" />
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:site" content="@JapanFormula" />

    <script type="application/ld+json">
      {JSON.stringify({
        "@context": "https://schema.org",
        "@type": "Organization",
        name: "Japan Formula",
        url: "https://japanformula.com/",
        logo: "https://japanformula.com/logo.png",
        sameAs: [
          "https://www.facebook.com/japanformula",
          "https://www.instagram.com/japanformula",
          "https://t.me/japanformula"
        ],
        contactPoint: {
          "@type": "ContactPoint",
          "contactType": "customer service",
          "areaServed": "RU",
          "availableLanguage": "Russian",
          "url": "https://t.me/loveourclients"
        },
        copyrightYear: new Date().getFullYear(),
        publisher: {
          "@type": "Organization",
          name: "Japan Formula",
          logo: "https://japanformula.com/images/logo-seo.png"
        }
      })}
    </script>
  </Helmet>
);

export default BaseHelmet;
