import dm1 from "../images/dm1-min.png";
import dm2 from "../images/dm2-min.png";
import mm1 from "../images/mm1-min.png";
import mm2 from "../images/mm2-min.png";
import mm3 from "../images/mm3-min.png";
import oz1 from "../images/oz1.webp";
import oz2 from "../images/oz2.webp";
import oz3 from "../images/oz3.webp";
import w1 from "../images/w1.webp";
import w2 from "../images/w2.webp";
import w3 from "../images/w3.webp";
import ym1 from "../images/ym1.webp";
import ym2 from "../images/ym2.webp";
import ym3 from "../images/ym3.webp";


export const cardsMainReviewsData = [

  { img: oz1, alt: "отзывы OZON. 2025г.", text: '2025г. отзывы c торговой площадки "OZON"' },
  { img: oz2, alt: "отзывы OZON 2025г.", text: '2025г. отзывы c торговой площадки "OZON"' },
  { img: oz3, alt: "отзывы OZON 2025г.", text: '2025г. отзывы c торговой площадки "OZON"' },
  { img: w1, alt: "отзывы 2025г. WILDBERRIES", text: '2025г. отзывы c торговой площадки "WILDBERRIES"' },
  { img: w2, alt: "отзывы 2025г. WILDBERRIES", text: '2025г. отзывы c торговой площадки "WILDBERRIES"' },
  { img: w3, alt: "отзывы 2025г. WILDBERRIES", text: '2025г. отзывы c торговой площадки "WILDBERRIES"' },
  { img: ym1, alt: "отзывы ЯндексМаркет 2025г.", text: '2025г. отзывы c торговой площадки "ЯндексМаркет"' },
  { img: ym2, alt: "отзывы ЯндексМаркет 2025г.", text: '2025г. отзывы c торговой площадки "ЯндексМаркет"' },
  { img: ym3, alt: "отзывы ЯндексМаркет 2025г.", text: '2025г. отзывы c торговой площадки "ЯндексМаркет"' },
  { img: dm1, alt: "отзывы Детский мир", text: 'отзывы c торговой площадки "Детский мир"' },
  { img: dm2, alt: "отзывы Детский мир", text: 'отзывы c торговой площадки "Детский мир"' },
  { img: mm1, alt: "отзывы МегаМаркет", text: 'отзывы c торговой площадки "МегаМаркет"' },
  { img: mm2, alt: "отзывы МегаМаркет", text: 'отзывы c торговой площадки "МегаМаркет"' },
  { img: mm3, alt: "отзывы МегаМаркет", text: 'отзывы c торговой площадки "МегаМаркет"' },
];

