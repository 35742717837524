import React, { useEffect } from "react";

import "./SocialMedia.css";
import telegram from "../../images/telegram.png";
import VK from "../../images/VK.png";

const SocialMedia = ({ handleTelegramClickJF, handleVkClick }) => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <section className="social-media" id="social-media">
      <div className="social-media__wrapper">
        <h1 className="social-media__title">
          Хотите узнавать первыми о наших новинках?
        </h1>
        <h2 className="social-media__subtitle">
          Будьте на шаг впереди! Подписывайтесь на наш Telegram-канал и
          ВКонтакте получайте последние новости, специальные предложения и
          вдохновение для достижения ваших целей.
        </h2>
        <div className="social-media__telegram-content">
          <img src={VK} alt="Иконка ВКонтакте" onClick={handleVkClick} />
          <img
            src={telegram}
            alt="Иконка телеграмм"
            onClick={handleTelegramClickJF}
          />
        </div>
        <p className="social-media__subtitle">Не пропустите ничего важного!</p>
      </div>
    </section>
  );
};

export default SocialMedia;
